import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { ExportCSV } from '../../../components/ExportCSV';
import './taskOrderList.css';
import { MDBDataTable } from 'mdbreact';
import { Switch, FormGroup, FormControlLabel, TextField } from '@material-ui/core/';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { makeStyles } from '@material-ui/core/styles';
import imageFilter from '../../../containers/img/filter.svg';

import BeatLoader from 'react-spinners/BeatLoader';
import DatePicker from 'react-date-picker';
import Select /*{ components }*/ from 'react-select';
import { dataTranslateDisa, dataTranslateSanfernando, listDataTranslateDisa, listDataTranslateSanfernando, DisaStartHeaders, SanfernandoStartHeaders, comboBoxDisa, comboBoxPeru, listDataTranslateLogistics, dataTranslateLogistics, logisticsStartHeaders, comboBoxLogistics } from '../../../Environments/TranslateDataTable';
import Column from '../../common/opcionesFilter/Column';
import { DragDropContext } from 'react-beautiful-dnd';
import { withTranslation } from 'react-i18next';
import Checkbox from '@material-ui/core/Checkbox';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const fileName = "download";
const spinnerCss = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    marginLeft: '-30px'
}

const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
};
const groupBadgeStyles = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
};

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: '#fff',
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class taskOrderList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            services: [],
            servicesTable: [],
            copyServices: [],
            isCopyServices: false,
            excelServices: [],
            loading: true,

            time: "",
            startDate: new Date(),
            endDate: new Date(),
            showDateFilters: false,
            snackbarOpen: false,
            snackbarMessage: "",
            showSnackbarHistorical: false,
            showSnackbarHistoricalMessage: "Actualmente se encuentra en modo histórico, por lo tanto la obtención de datos automáticas se ha parado temporalmente.",
            options: [
                { value: 'BAQ-783', label: 'BAQ-783' },
                { value: 'BAQ-784', label: 'BAQ-784' },
                { value: 'BAQ-785', label: 'BAQ-785' }
            ],
            groupedOptionsTractoList: [],
            groupedOptionsTolvaList: [],
            groupedOptionsPlantelList: [],
            groupedOptionsStateList: [],
            groupedOptionsDriverList: [],
            groupedOptionsOrgVentasList: [],

            tractoList: [],
            tolvaList: [],
            plantelList: [],
            stateList: [],
            driverList: [],
            orgVentasList: [],

            valueTracto: [],
            valueTolva: [],
            valuePlantel: [],
            valueState: [],
            valueDriver: [],
            valueOrgVentas: [],
            valueTextToBeSearch: "",

            globalFilter: {
                combo1: [],
                combo2: [],
                combo3: [],
                combo4: [],
                combo5: [],
                combo6: [],
                combo7: [],
            },

            dataTableTranslate: [],
            listTableTranslate: {},

            viewFilters: false,

            modalShow: false,

            classes: useStyles,
            newHeaders: [],
            headersSelected: [],
            headersNotSelected: [],

            newColumns: [],
            modalMobile: (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) ? false : true,
            widthCells: "140px",
            comboBoxText: {},

        }
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    async componentDidMount() {
        document.getElementById("pin").style.display = "none";
        if (this.props.isAuthenticated === false) {
            this.redirectToLogin();
        } else {
            let company = this.props.companyName;
            company = company.toLocaleLowerCase();
            await this.getOptionsDataSelected(company, "Seleccionadas", "NoSeleccionadas");

            // await this.getOptionsDataNotSelected();

            // this.getData(company, selected, notSelected);
            //this.timer = setInterval(() => this.getOptionsDataSelected(company, "Seleccionadas", "NoSeleccionadas"), 600000);
            this.startTimer();
        }

    }

    startTimer = () => {
        this.timer = setInterval(() => this.getOptionsDataSelected('company', 'Seleccionadas', 'NoSeleccionadas'), 600000);
    };

    // Detener el timer
    stopTimer = () => {
        if (this.timer) {
            clearInterval(this.timer);
            this.timer = null;
        }
    };

    handleSnackbarOpen = (message) => {
        this.setState({
            snackbarOpen: true,
            snackbarMessage: message,
        });
    };

    handleSnackbarClose = () => {
        this.setState({
            snackbarOpen: false,
            snackbarMessage: "",
        });
    };

    handleApplyFilters = async () => {
        // Validar que la fecha de fin esté definida y sea mayor o igual a la fecha de inicio
        if (!this.state.endDate) {
            this.handleSnackbarOpen("Por favor, seleccione una fecha de fin.");
            return;
        }
        if (this.state.endDate < this.state.startDate) {
            this.handleSnackbarOpen("La fecha de fin no puede ser menor que la fecha de inicio.");
            return;
        }
        let company = this.props.companyName;
        company = company.toLocaleLowerCase();

        this.setState({
            loading: true
        })

        await this.getOptionsDataSelected(company, "Seleccionadas", "NoSeleccionadas");

    }

    async getData(company, selectedOptions, notSelectedOptions) {
        let jsonServices = [];
        let responseServices = await fetch(
            `${this.props.location.state.ApiURL}Services?startDate=${this.DateTimeToTicks(Date.UTC(
                this.state.startDate.getFullYear(),
                this.state.startDate.getMonth(),
                this.state.startDate.getDate()
            ))}&endDate=${this.DateTimeToTicks(Date.UTC(
                this.state.endDate.getFullYear(),
                this.state.endDate.getMonth(),
                this.state.endDate.getDate(),
                23, // Hora: 23
                59, // Minuto: 59
                0   // Segundo: 0
            ))}`,
            {
                headers: {
                    'accessToken': this.props.location.state.AccessToken
                },
            }
        );
        if (responseServices.ok) {
            jsonServices = await responseServices.json();

        } else {
            alert("HTTP-Error: " + responseServices.status);
        }
        if (jsonServices[0] !== undefined) {
            if (jsonServices[0].cabeza === null) {
                this.setState({
                    loading: false,
                    listDataTable: []
                });
                return;
            }
        }
        this.showData(jsonServices, company, null, listDataTranslateDisa, selectedOptions, notSelectedOptions);

    }



    parseDateByString = (dateParam) => {
        let dateTime = dateParam.split(" ");
        let time = dateTime[1].split(":");
        let date = dateTime[0].split("/");
        return new Date(date[2], date[1], date[0], time[0], time[1], time[2]);
    }

    calculateDifferenceOfTime = (originOutputDate, originArrivalDate) => {

        let result = "";
        if (originOutputDate !== "--" && originArrivalDate !== "--") {
            const originDate = this.parseDateByString(originOutputDate);
            const arrivalDate = this.parseDateByString(originArrivalDate);
            let diff = (originDate.getTime() - arrivalDate.getTime()) / 1000;
            diff /= 60;
            result = this.timeConvert(Math.abs(Math.round(diff)));
        }

        return result;

    }
    timeConvert(n) {

        var num = n;
        var hours = (num / 60);
        var rhours = Math.floor(hours);
        var minutes = (hours - rhours) * 60;
        var rminutes = Math.round(minutes);
        if (rhours !== 0)
            return rhours + "h " + rminutes + "m";
        else
            return rminutes + "m";

    }

    showData(jsonServices, company, filtersHeaders, listDataTranslateDisa, selected, notSelected) {
        let tractoList = [];
        let tolvaList = [];
        let plantelList = [];
        let stateList = [];
        let driverList = [];
        let orgVentasList = [];
        let dataTableTranslate = [];
        let jsonProducts = [];
        let listDataTranslate = [];
        let resultSelected = [];
        let resultNotSelected = [];
        let newStateSelected = {};
        let newHeaders = [];
        let comboBoxText = {};

        let excelServices = [];

        if (company === "sanfernando") {
            dataTableTranslate = dataTranslateSanfernando;
            listDataTranslate = listDataTranslateSanfernando;
            newHeaders = SanfernandoStartHeaders;
            comboBoxText = comboBoxPeru;

        } else if (company.toLowerCase() === "disagestionlogistica") {
            dataTableTranslate = dataTranslateDisa;
            listDataTranslate = listDataTranslateDisa;
            newHeaders = DisaStartHeaders;
            comboBoxText = comboBoxDisa;
        } else if (company.toLowerCase() === "logistics") {
            dataTableTranslate = dataTranslateLogistics;
            listDataTranslate = listDataTranslateLogistics;
            newHeaders = logisticsStartHeaders;
            comboBoxText = comboBoxLogistics;
        } else {
            dataTableTranslate = dataTranslateLogistics;
            listDataTranslate = listDataTranslateLogistics;
            newHeaders = logisticsStartHeaders;
            comboBoxText = comboBoxLogistics;
        }

        let newDataTable = {
            columns: [],
            rows: []
        }

        newHeaders.forEach(header => {

            if (header.content.toLowerCase() === "estado") {
                newDataTable.columns.push({
                    label: header.content,
                    field: header.label,
                    width: 100
                });
            } else {
                newDataTable.columns.push({
                    label: header.content,
                    field: header.label,
                    width: 100
                });
            }
        });

        jsonServices.forEach(element => {
            if (element !== null) {
                if (element.hasOwnProperty("productos")) {
                    if (element.productos.length > 0) {
                        //console.log(element.descripcion)

                        jsonProducts = element.productos;

                        // Verifica si jsonProducts es una cadena antes de intentar parsear el JSON
                        if (typeof jsonProducts === 'string') {
                            try {
                                jsonProducts = JSON.parse(jsonProducts);
                            } catch (error) {
                                console.error("Error al parsear JSON:", error);
                            }
                        }
                    }
                }
            }

            newDataTable.rows.push({
                // "Estado": element.estado,
                "estado": element.estado === "Planta" ?
                    <label id={element.descripcion} style={{ background: "#5d705f", padding: "10px", color: "#fff", width: "73px" }}>{element.estado}</label>
                    : element.estado.toString() === "En Ruta" ?
                        <label id={element.descripcion} style={{ background: "#b0ae2c", padding: "10px", color: "#fff", width: "73px" }}>{element.estado}</label>
                        : element.estado.toString() === "Granja" ?
                            <label id={element.descripcion} style={{ background: "#bf9f7a", padding: "10px", color: "#fff", width: "73px" }}>{element.estado}</label>
                            : element.estado.toString() === "Pendiente" ?
                                <label id={element.descripcion} style={{ background: "#878787", padding: "10px", color: "#fff", width: "78px" }}>{element.estado}</label>
                                : element.estado === "Cliente" ?
                                    <label id={element.descripcion} style={{ background: "#bf9f7a", padding: "10px", color: "#fff", width: "73px" }}>{element.estado}</label>
                                    : element.estado === "Retorno" ?
                                        <label id={element.descripcion} style={{ background: "#4f456e", padding: "10px", color: "#fff", width: "73px" }}>{element.estado}</label>
                                        : element.estado === "Cargadero" || element.estado === "Finalizado" ?
                                            <label id={element.descripcion} style={{ background: "#5d705f", padding: "10px", color: "#fff", width: "73px" }}>Finalizado</label>
                                            : element.estado,
                "orgVentas": element.orgVentas,
                "descripcion": element.descripcion,
                "cabeza": element.cabeza,
                "remolque": element.remolque,
                "conductor": element.conductor,
                "tfnoConductor": element.tfnoConductor,
                "destino": element.destino,
                "idRegionDestino": element.idRegionDestino,
                "productos": Array.isArray(jsonProducts) && jsonProducts.length > 0 ? jsonProducts.map(item => item.N + " " + item.C + " " + item.U) : [],
                "idRegionCargadero": element.idRegionCargadero,
                "salidaCargadero": <label style={{ background: "#4287f5", padding: "10px", color: "#fff", width: "73px" }}>{element.salidaCargadero}</label>,
                "llegadaCargadero": <label style={{ background: "#4287f5", padding: "10px", color: "#fff", width: "73px" }}>{element.llegadaCargadero}</label>,
                "etaEstimadaLlegada": element.etaEstimadaLlegada,
                "etaEstimadaSalida": element.etaEstimadaSalida,
                "etaLlegadaCliente": element.etaLlegadaCliente,
                "llegadaCliente": <label style={{ background: "#4287f5", padding: "10px", color: "#fff", width: "73px" }}>{element.llegadaCliente}</label>,
                "salidaCliente": <label style={{ background: "#4287f5", padding: "10px", color: "#fff", width: "73px" }}>{element.salidaCliente}</label>,
                "etaEstimadaLlegadaCargadero": element.etaEstimadaLlegadaCargadero,
                "etaLlegadaCargadero": element.etaLlegadaCargadero,
                "kmsEstimados": <label style={{ background: "#73877B", padding: "10px", color: "#fff", width: "73px" }}>{element.kmsEstimados}</label>,
                "kmsRecorridos": <label style={{ background: "#5C8001", padding: "10px", color: "#fff", width: "73px" }}>{element.kmsRecorridos}</label>,
                "velocidadMedia": element.velocidadMedia,
                "velocidadMaxima": element.velocidadMedia,
                "eventosMobilEye": element.velocidadMedia,
                "orden": element.orden,
                "fechaActivacion": element.fechaActivacion,
                "fechaServicio": element.fechaProgramada,
                "diffTimeOt": this.calculateDifferenceOfTime(element.salidaCargadero, element.llegadaCargadero),
                "idRegionFinCargadero": element.idRegionFinCargadero,
                "temperatura": element.temperatura,

            });
            excelServices.push({
                "estado": element.estado,
                "orgVentas": element.orgVentas,
                "descripcion": element.descripcion,
                "cabeza": element.cabeza,
                "remolque": element.remolque,
                "conductor": element.conductor,
                "tfnoConductor": element.tfnoConductor,
                "destino": element.destino,
                "idRegionDestino": element.idRegionDestino,
                "productos": jsonProducts,
                "idRegionCargadero": element.idRegionCargadero,
                "salidaCargadero": element.salidaCargadero,
                "llegadaCargadero": element.llegadaCargadero,
                "etaEstimadaLlegada": element.etaEstimadaLlegada,
                "etaEstimadaSalida": element.etaEstimadaSalida,
                "etaLlegadaCliente": element.etaLlegadaCliente,
                "llegadaCliente": element.llegadaCliente,
                "salidaCliente": element.salidaCliente,
                "etaEstimadaLlegadaCargadero": element.etaEstimadaLlegadaCargadero,
                "etaLlegadaCargadero": element.etaLlegadaCargadero,
                "kmsEstimados": element.kmsEstimados,
                "kmsRecorridos": element.kmsRecorridos,
                "velocidadMedia": element.velocidadMedia,
                "velocidadMaxima": element.velocidadMedia,
                "eventosMobilEye": element.velocidadMedia,
                "orden": element.orden,
                "fechaActivacion": element.fechaActivacion,
                "fechaServicio": element.fechaProgramada,
                "diffTimeOt": this.calculateDifferenceOfTime(element.salidaCargadero, element.llegadaCargadero),
                "idRegionFinCargadero": element.idRegionFinCargadero,
                "temperatura": element.temperatura,
            });

            tractoList.push({ tag: "Matricula", value: element.cabeza, label: element.cabeza });
            tolvaList.push({ tag: "Tolva", value: element.remolque, label: element.remolque });
            plantelList.push({ tag: "Plantel", value: element.destino, label: element.destino });
            stateList.push({ tag: "Estados", value: element.estado, label: element.estado });
            driverList.push({ tag: "Conductor", value: element.conductor, label: element.conductor });
            orgVentasList.push({ tag: "OrgVentas", value: element.orgVentas, label: element.orgVentas });
        });

        let uniqueArray = this.RemoveDuplicates(stateList);
        stateList = uniqueArray;
        stateList.sort((a, b) => a.label.localeCompare(b.label));

        uniqueArray = this.RemoveDuplicates(plantelList);
        plantelList = uniqueArray;
        plantelList.sort((a, b) => a.label.localeCompare(b.label));

        uniqueArray = this.RemoveDuplicates(tolvaList);
        tolvaList = uniqueArray;
        tolvaList.sort((a, b) => a.label.localeCompare(b.label));

        uniqueArray = this.RemoveDuplicates(tractoList);
        tractoList = uniqueArray;
        tractoList.sort((a, b) => a.label.localeCompare(b.label));

        uniqueArray = this.RemoveDuplicates(driverList);
        driverList = uniqueArray;
        driverList.sort((a, b) => a.label.localeCompare(b.label));

        uniqueArray = this.RemoveDuplicates(orgVentasList);
        orgVentasList = uniqueArray;
        orgVentasList.sort((a, b) => a.label.localeCompare(b.label));

        let newDataTable2 = {
            columns: [],
            rows: []
        }

        if (selected !== null && notSelected !== null) {
            newHeaders = selected;
            resultSelected = selected.map((element) => (element.id));
            resultNotSelected = notSelected.map((element) => (element !== undefined ? element.id : []));

            newStateSelected = {
                ...listDataTranslate,
                columns: {
                    ...listDataTranslate.columns,
                    "column-2": {
                        id: 'column-2',
                        title: 'Opciones seleccionadas',
                        headersIds: resultSelected
                    },
                    "column-1": {
                        id: 'column-1',
                        title: 'Opciones',
                        headersIds: resultNotSelected
                    }
                }
            };
            let test = selected.map((element) => (element.label));
            let filteredparam1 = [];

            newHeaders.forEach(header => {
                newDataTable2.columns.push({
                    label: header.content,
                    field: header.label,
                    width: 130
                });

            });

            newDataTable.rows.forEach((key, value) => {
                let tmp = this.startCopyObjectProps(key, test);
                filteredparam1.push(tmp);
            });

            newDataTable2.rows = filteredparam1;
        } else {
            let selecteds = "";
            if (company === "sanfernando") {
                resultSelected = SanfernandoStartHeaders;
                resultNotSelected = [];
                selecteds = resultSelected.map((element) => (element.label));
                resultSelected = SanfernandoStartHeaders.map((element) => (element.id));
                if (resultNotSelected.length > 0) {
                    resultNotSelected = resultNotSelected.map((element) => (element.id));
                }
                newStateSelected = {
                    ...listDataTranslate,
                    columns: {
                        ...listDataTranslate.columns,
                        "column-2": {
                            id: 'column-2',
                            title: 'Opciones seleccionadas',
                            headersIds: resultSelected
                        },
                        "column-1": {
                            id: 'column-1',
                            title: 'Opciones',
                            headersIds: resultNotSelected
                        }
                    }
                };
            } else if (company.toLowerCase() === "disagestionlogistica") {
                resultSelected = DisaStartHeaders;
                resultNotSelected = [];
                selecteds = resultSelected.map((element) => (element.label));
                resultSelected = DisaStartHeaders.map((element) => (element.id));
                if (resultNotSelected.length > 0) {
                    resultNotSelected = resultNotSelected.map((element) => (element.id));
                }
                newStateSelected = {
                    ...listDataTranslate,
                    columns: {
                        ...listDataTranslate.columns,
                        "column-2": {
                            id: 'column-2',
                            title: 'Opciones seleccionadas',
                            headersIds: resultSelected
                        },
                        "column-1": {
                            id: 'column-1',
                            title: 'Opciones',
                            headersIds: resultNotSelected
                        }
                    }
                };
            } else if (company.toLowerCase() === "logistics") {
                resultSelected = logisticsStartHeaders;
                resultNotSelected = [];
                selecteds = resultSelected.map((element) => (element.label));
                resultSelected = logisticsStartHeaders.map((element) => (element.id));
                if (resultNotSelected.length > 0) {
                    resultNotSelected = resultNotSelected.map((element) => (element.id));
                }
                newStateSelected = {
                    ...listDataTranslate,
                    columns: {
                        ...listDataTranslate.columns,
                        "column-2": {
                            id: 'column-2',
                            title: 'Opciones seleccionadas',
                            headersIds: resultSelected
                        },
                        "column-1": {
                            id: 'column-1',
                            title: 'Opciones',
                            headersIds: resultNotSelected
                        }
                    }
                };
            } else if (company.toLowerCase() === "overtruck") {
                console.log(logisticsStartHeaders);
                resultSelected = logisticsStartHeaders;
                resultNotSelected = [];
                selecteds = resultSelected.map((element) => (element.label));
                resultSelected = logisticsStartHeaders.map((element) => (element.id));
                if (resultNotSelected.length > 0) {
                    resultNotSelected = resultNotSelected.map((element) => (element.id));
                }
                newStateSelected = {
                    ...listDataTranslate,
                    columns: {
                        ...listDataTranslate.columns,
                        "column-2": {
                            id: 'column-2',
                            title: 'Opciones seleccionadas',
                            headersIds: resultSelected
                        },
                        "column-1": {
                            id: 'column-1',
                            title: 'Opciones',
                            headersIds: resultNotSelected
                        }
                    }
                };
            }


            let filteredparam1 = [];
            newHeaders.forEach(header => {
                newDataTable2.columns.push({
                    label: header.content,
                    field: header.label,
                    width: 130
                });

            });
            newDataTable.rows.forEach((key, value) => {
                let tmp = this.startCopyObjectProps(key, selecteds);
                filteredparam1.push(tmp);
            });

            newDataTable2.rows = filteredparam1;
        }

        this.setState({
            // services: newServices,
            copyServices: newDataTable,
            excelServices: newDataTable.rows,
            loading: false,
            time: this.formatDate(),
            tractoList: tractoList,
            tolvaList: tolvaList,
            plantelList: plantelList,
            stateList: stateList,
            listDataTable: newDataTable2,
            groupedOptionsTractoList: [{
                label: 'Tracto',
                options: tractoList,
            }],
            groupedOptionsTolvaList: [{
                label: 'Tolva',
                options: tolvaList,
            }],
            groupedOptionsPlantelList: [{
                label: 'Plantel',
                options: plantelList,
            }],
            groupedOptionsStateList: [{
                label: 'Estados',
                options: stateList,
            }],
            groupedOptionsDriverList: [{
                label: 'Conductores',
                options: driverList,
            }],
            groupedOptionsOrgVentasList: [{
                label: 'OrgVentas',
                options: orgVentasList
            }],
            dataTableTranslate: dataTableTranslate,
            listTableTranslate: newStateSelected,
            newHeaders: newHeaders,
            // headersSelected: selected,
            // headersNotSelected: notSelected
            headersNotSelectedObject: notSelected,

            headersNotSelected: notSelected,
            headersSelected: selected !== null ? selected : resultSelected,
            comboBoxText: comboBoxText
            // listTableTranslate: newListDataTranslate,

        }, () => {
            this.filterGlobal(true);
        });
    }

    filterGlobal(comeFromFilters) {
        let filteredList = this.state.copyServices.rows;

        for (const key in this.state.globalFilter) {
            if (this.state.globalFilter.hasOwnProperty(key)) {
                const element = this.state.globalFilter[key];
                if (element.length > 0) {
                    filteredList = this.filterTest(element, filteredList, key);
                }
            }
        }
        // console.log(filteredList);
        let newDataTable = {
            ...this.state.listDataTable,
            rows: filteredList
        }

        this.applyFilter(newDataTable, comeFromFilters);
        // return filteredList;
    }

    applyFilter = async (arrListDataTable, comeFromFilters) => {

        const { newHeaders, headersNotSelected } = this.state;
        var filteredparam1 = [];
        let excelServices = [];

        let result = newHeaders.map((element) => (element.label));

        const newListDataTable = {
            ...arrListDataTable,
            columns: []
        }

        newHeaders.forEach(header => {
            newListDataTable.columns.push({
                label: header.content,
                field: header.label,
                width: 130
            });
        });

        arrListDataTable.rows.forEach((key, element) => {
            let tmp = this.copyObjectProps(key, result);
            filteredparam1.push(tmp);
        });

        newListDataTable.rows = filteredparam1;
        // console.log(filteredparam1);
        let dataExcelServices = [...arrListDataTable.rows];
        let company = this.props.companyName;
        dataExcelServices.forEach((key, element) => {
            if (result !== undefined) {

                let tmp = this.copyObjectProps(key, result);
                // console.log(element.estado, element.estado.props.children);
                // console.log(tmp);
                if (tmp.estado !== undefined) {
                    tmp.estado = tmp.estado.props.children;
                }
                if (tmp.productos !== undefined) {
                    tmp.productos = tmp.productos.toString();
                }

                if (tmp.salidaCargadero !== undefined) {
                    tmp.salidaCargadero = tmp.salidaCargadero.props.children
                }
                if (tmp.llegadaCargadero !== undefined) {
                    tmp.llegadaCargadero = tmp.llegadaCargadero.props.children
                }

                if (tmp.llegadaCliente !== undefined) {
                    tmp.llegadaCliente = tmp.llegadaCliente.props.children
                }
                if (tmp.salidaCliente !== undefined) {
                    tmp.salidaCliente = tmp.salidaCliente.props.children
                }
                if (tmp.kmsEstimados !== undefined) {
                    tmp.kmsEstimados = tmp.kmsEstimados.props.children
                }
                if (tmp.kmsRecorridos !== undefined) {
                    tmp.kmsRecorridos = tmp.kmsRecorridos.props.children
                }

                excelServices.push(tmp);
            }

        });

        excelServices.forEach(element => {
            // console.log(keys);
            for (let key in element) {
                if (element.hasOwnProperty.call(element, key)) {
                    // console.log(element);
                    const elementObject = element[key];
                    // console.log(elementObject)
                    switch (key) {
                        case "orgVentas":
                            if (company.toLowerCase() === "sanfernando") {
                                element["Pto.Planificación"] = element[key]
                                delete element[key];
                            }
                            break;
                        case "descripcion":
                            if (company.toLowerCase() === "sanfernando") {
                                element["Orden Servicio"] = element[key]
                                delete element[key];
                            }
                            break;
                        case "fechaServicio":
                            if (company.toLowerCase() === "sanfernando") {
                                element["Fecha Inicio Programada"] = element[key]
                                delete element[key];
                            }
                            break;
                        case "cabeza":
                            if (company.toLowerCase() === "sanfernando") {
                                element["Tracto"] = element[key]
                                delete element[key];
                            }
                            break;
                        case "remolque":
                            if (company.toLowerCase() === "sanfernando") {
                                element["Tolva"] = element[key]
                                delete element[key];
                            }
                            break;
                        case "destino":
                            if (company.toLowerCase() === "sanfernando") {
                                element["Destino"] = element[key]
                                delete element[key];
                            } else if (company.toLowerCase() === "logistics") {
                                element["Región"] = element[key]
                                delete element[key];
                            }
                            break;
                        case "tfnoConductor":
                            if (company.toLowerCase() === "sanfernando") {
                                element["Tfno Conductor"] = element[key]
                                delete element[key];
                            } else if (company.toLowerCase() === "logistics") {
                                element["Tfno Conductor"] = element[key]
                                delete element[key];
                            } else if (company.toLowerCase() === "disagestionlogistica") {
                                element["Tfno Conductor"] = element[key]
                                delete element[key];
                            }
                            break;
                        case "idRegionDestino":
                            if (company.toLowerCase() === "sanfernando") {
                                element["Id Region Destino"] = element[key]
                                delete element[key];
                            } else if (company.toLowerCase() === "logistics") {
                                element["Id Region Destino"] = element[key]
                                delete element[key];
                            } else if (company.toLowerCase() === "disagestionlogistica") {
                                element["Id Region Destino"] = element[key]
                                delete element[key];
                            }
                            break;
                        case "idRegionCargadero":
                            if (company.toLowerCase() === "sanfernando") {
                                element["Id Region Origen"] = element[key]
                                delete element[key];
                            } else if (company.toLowerCase() === "logistics") {
                                element["Id Region Base"] = element[key]
                                delete element[key];
                            } else if (company.toLowerCase() === "disagestionlogistica") {
                                element["Id Region Destino"] = element[key]
                                delete element[key];
                            }
                            break;
                        case "salidaCargadero":
                            if (company.toLowerCase() === "sanfernando") {
                                element["Salida Origen"] = element[key]
                                delete element[key];
                            } else if (company.toLowerCase() === "logistics") {
                                element["Salida Base"] = element[key]
                                delete element[key];
                            } else if (company.toLowerCase() === "disagestionlogistica") {
                                element["Salida Cargadero"] = element[key]
                                delete element[key];
                            }
                            break;
                        case "llegadaCargadero":
                            if (company.toLowerCase() === "sanfernando") {
                                element["Retorno Origen"] = element[key]
                                delete element[key];
                            } else if (company.toLowerCase() === "logistics") {
                                element["Llegada Base"] = element[key]
                                delete element[key];
                            } else if (company.toLowerCase() === "disagestionlogistica") {
                                element["Finalizado"] = element[key]
                                delete element[key];
                            }
                            break;
                        case "etaEstimadaLlegada":
                            if (company.toLowerCase() === "sanfernando") {
                                element["Ventana Min SF"] = element[key]
                                delete element[key];
                            } else if (company.toLowerCase() === "logistics") {
                                element["Llegada Estimada"] = element[key]
                                delete element[key];
                            } else if (company.toLowerCase() === "disagestionlogistica") {
                                element["Llegada Estimada"] = element[key]
                                delete element[key];
                            }
                            break;
                        case "etaEstimadaSalida":
                            if (company.toLowerCase() === "sanfernando") {
                                element["Ventana Max SF"] = element[key]
                                delete element[key];
                            } else if (company.toLowerCase() === "logistics") {
                                element["Salida Estimada"] = element[key]
                                delete element[key];
                            } else if (company.toLowerCase() === "disagestionlogistica") {
                                element["Salida Estimada"] = element[key]
                                delete element[key];
                            }
                            break;
                        case "etaLlegadaCargadero":
                            if (company.toLowerCase() === "sanfernando") {
                                element["Eta Retorno Origen"] = element[key]
                                delete element[key];
                            } else if (company.toLowerCase() === "logistics") {
                                element["Eta Llegada Salida"] = element[key]
                                delete element[key];
                            } else if (company.toLowerCase() === "disagestionlogistica") {
                                element["Eta Llegada Cliente"] = element[key]
                                delete element[key];
                            }
                            break;
                        case "llegadaCliente":
                            if (company.toLowerCase() === "sanfernando") {
                                element["Llegada Destino"] = element[key]
                                delete element[key];
                            } else if (company.toLowerCase() === "logistics") {
                                element["Llegada Región"] = element[key]
                                delete element[key];
                            } else if (company.toLowerCase() === "disagestionlogistica") {
                                element["Llegada Cliente"] = element[key]
                                delete element[key];
                            }
                            break;
                        case "salidaCliente":
                            if (company.toLowerCase() === "sanfernando") {
                                element["Salida Destino"] = element[key]
                                delete element[key];
                            } else if (company.toLowerCase() === "logistics") {
                                element["Salida Región"] = element[key]
                                delete element[key];
                            } else if (company.toLowerCase() === "disagestionlogistica") {
                                element["Salida Cliente"] = element[key]
                                delete element[key];
                            }
                            break;
                        case "etaEstimadaLlegadaCargadero":
                            if (company.toLowerCase() === "sanfernando") {
                                element["Eta Retorno SF"] = element[key]
                                delete element[key];
                            } else if (company.toLowerCase() === "logistics") {
                                element["Eta Estimada Llegada Base"] = element[key]
                                delete element[key];
                            } else if (company.toLowerCase() === "disagestionlogistica") {
                                element["Eta Estimada Llegada Cargadero"] = element[key]
                                delete element[key];
                            }
                            break;
                        case "etaLlegadaCargadero":
                            if (company.toLowerCase() === "sanfernando") {
                                element["Eta Retorno Origen"] = element[key]
                                delete element[key];
                            } else if (company.toLowerCase() === "logistics") {
                                element["Eta Llegada Base"] = element[key]
                                delete element[key];
                            } else if (company.toLowerCase() === "disagestionlogistica") {
                                element["Eta Llegada Cargadero"] = element[key]
                                delete element[key];
                            }
                            break;
                        case "kmsEstimados":
                            if (company.toLowerCase() === "sanfernando") {
                                element["Kms Estimados"] = element[key]
                                delete element[key];
                            } else if (company.toLowerCase() === "logistics") {
                                element["Kms Estimados"] = element[key]
                                delete element[key];
                            } else if (company.toLowerCase() === "disagestionlogistica") {
                                element["Kms Estimados"] = element[key]
                                delete element[key];
                            }
                            break;
                        case "kmsRecorridos":
                            if (company.toLowerCase() === "sanfernando") {
                                element["Kms Recorridos"] = element[key]
                                delete element[key];
                            } else if (company.toLowerCase() === "logistics") {
                                element["Kms Recorridos"] = element[key]
                                delete element[key];
                            } else if (company.toLowerCase() === "disagestionlogistica") {
                                element["Kms Recorridos"] = element[key]
                                delete element[key];
                            }
                            break;
                        case "velocidadMedia":
                            if (company.toLowerCase() === "sanfernando") {
                                element["Velocidad Media"] = element[key]
                                delete element[key];
                            } else if (company.toLowerCase() === "logistics") {
                                element["Velocidad Media"] = element[key]
                                delete element[key];
                            } else if (company.toLowerCase() === "disagestionlogistica") {
                                element["Velocidad Media"] = element[key]
                                delete element[key];
                            }
                            break;
                        case "velocidadMaxima":
                            if (company.toLowerCase() === "logistics") {
                                element["Velocidad Máxima"] = element[key]
                                delete element[key];
                            }
                            break;
                        case "eventosMobilEye":
                            if (company.toLowerCase() === "logistics") {
                                element["Eventos mobileye"] = element[key]
                                delete element[key];
                            }
                            break;
                        case "fechaActivacion":
                            if (company.toLowerCase() === "sanfernando") {
                                element["Fecha Activación"] = element[key]
                                delete element[key];
                            }
                            break;
                        case "fechaServicio":
                            if (company.toLowerCase() === "sanfernando") {
                                element["Fecha Servicio"] = element[key]
                                delete element[key];
                            }
                            break;
                        case "etaLlegadaCliente":

                            if (company.toLowerCase() === "sanfernando") {
                                element["Eta Llegada Destino"] = element[key]
                                delete element[key];
                            } else if (company.toLowerCase() === "logistics") {
                                element["Eta Llegada Región"] = element[key]
                                delete element[key];
                            } else if (company.toLowerCase() === "disagestionlogistica") {
                                element["Eta Llegada Cliente"] = element[key]
                                delete element[key];
                            }
                            break;
                        case "diffTimeOt":

                            if (company.toLowerCase() === "sanfernando") {
                                element["Tiempo OT"] = element[key]
                                delete element[key];
                            } else if (company.toLowerCase() === "logistics") {
                                element["Tiempo OT"] = element[key]
                                delete element[key];
                            } else if (company.toLowerCase() === "disagestionlogistica") {
                                element["Tiempo OT"] = element[key]
                                delete element[key];
                            } else if (company.toLowerCase() === "overtruck") {
                                element["Tiempo OT"] = element[key]
                                delete element[key];
                            }
                            break;
                        default:
                            break;
                    }
                }
            }
        });



        if (comeFromFilters === true) {
            // console.log(comeFromFilters);
            this.setState({
                listDataTable: newListDataTable,
                headersSelected: newHeaders,
                headersNotSelected: headersNotSelected,
                listEditDataTable: [],
                isLoadingEdit: false,
                excelServices: excelServices
            });
        } else {
            this.setState({
                listDataTable: newListDataTable,
                headersSelected: newHeaders,
                headersNotSelected: headersNotSelected,
                listEditDataTable: [],
                modalShow: false,
                excelServices: excelServices
            }, async () => {
                await this.saveOptionsData(newHeaders, this.state.headersNotSelected, "Seleccionadas", "NoSeleccionadas");
                // this.handleOnSort();
            });
        }
    }

    saveDataExcel(data) {
        data.forEach((element) => {
            // console.log(element.estado, element.estado.props.children);
            element.estado = element.estado.props.children;
            element.productos = element.productos.toString();
        });
    }

    startCopyObjectProps = (source, keys) => {
        let newObject = {}
        keys.forEach(function (key) {
            if (key.hasOwnProperty("label")) {
                newObject[key.label] = source[key.label]
            } else {
                newObject[key] = source[key]
            }


        })
        return newObject
    }

    filterTest(element, filteredList, key) {
        let arr = [];
        switch (key) {
            case "combo1":
                for (let i = 0; i < element.length; i++) {

                    filteredList.filter(item => {
                        if (item["cabeza"] === element[i]) {
                            arr.push(item);
                        }
                        return item;
                    });
                }
                break;
            case "combo2":
                for (let i = 0; i < element.length; i++) {

                    filteredList.filter(item => {
                        if (item["remolque"] === element[i]) {
                            arr.push(item);
                        }
                        return item;
                    });
                }
                break;
            case "combo3":
                for (let i = 0; i < element.length; i++) {

                    filteredList.filter(item => {
                        if (item["destino"] === element[i]) {
                            arr.push(item);
                        }
                        return item;
                    });
                }
                break;
            case "combo4":
                for (let i = 0; i < element.length; i++) {
                    filteredList.filter(item => {
                        // let descripcion = item.descripcion;
                        // descripcion = descripcion.toString();

                        // if (document.getElementById(descripcion) !== null) {

                        //     let labelState = document.getElementById(descripcion).textContent;
                        //     console.log(labelState, element[i])
                        //     if (labelState === element[i]) {
                        //         arr.push(item);
                        //     }
                        // }
                        // else {
                        //     let descripcion = item["estado"].props.children;
                        //     descripcion = descripcion.toString();
                        //     if (descripcion === element[i]) {
                        //         arr.push(item);
                        //     }
                        // }
                        let descripcion = item["estado"].props.children;
                        descripcion = descripcion.toString();
                        if (descripcion === element[i]) {
                            arr.push(item);
                        }
                        return item;
                    });
                }
                break;
            case "combo5":
                for (let i = 0; i < element.length; i++) {

                    filteredList.filter(item => {
                        if (item["orgVentas"] === element[i]) {
                            arr.push(item);
                        }
                        return item;
                    });
                }
                break;

            case "combo6":
                for (let i = 0; i < element.length; i++) {
                    filteredList.filter(item => {
                        if (item["conductor"] === element[i]) {
                            arr.push(item);
                        }
                        return item;
                    });
                }

                break;
            case "combo7":
                for (let i = 0; i < element.length; i++) {
                    filteredList.filter(item => {
                        if (item["descripcion"].match(element[i])) {
                            arr.push(item);
                        }
                        return item;
                    });
                }

                break;
            default:
                break;
        }
        return arr;
    }

    async getOptionsDataSelected(company, textSelectedOptions, textNotSelectedOptions) {
        let response = {};
        const settings = {
            method: 'GET',
            headers: {
                'accessToken': this.props.location.state.AccessToken,

            }
        };
        let responseServices = await fetch(this.props.location.state.ApiURL + "UserLogin/Key/" + textSelectedOptions, settings);
        // let responseServices = await fetch(this.props.location.state.ApiURL + "Services?" + "ServicesDate=" +

        if (responseServices.ok) {
            if (responseServices !== null || responseServices !== undefined) {
                try {
                    response = await responseServices.json();
                } catch (error) {
                    response = null;
                }
            }
        } else {
            alert("HTTP-Error: " + responseServices.status);
        }
        // response = null;
        this.getOptionsDataNotSelected(company, response, textNotSelectedOptions);
    }

    async getOptionsDataNotSelected(company, selectedOptions, textNotSelectedOptions) {
        let response = {};
        const settings = {
            method: 'GET',
            headers: {
                'accessToken': this.props.location.state.AccessToken,

            }
        };
        let responseServices = await fetch(this.props.location.state.ApiURL + "UserLogin/Key/" + textNotSelectedOptions, settings);
        // let responseServices = await fetch(this.props.location.state.ApiURL + "Services?" + "ServicesDate=" +

        if (responseServices.ok) {
            if (responseServices !== null || responseServices !== undefined) {
                try {
                    response = await responseServices.json();
                } catch (error) {
                    response = null;
                }
            }
            // response = null;
            await this.getData(company, selectedOptions, response);

        } else {
            alert("HTTP-Error: " + responseServices.status);
        }

    }

    async saveOptionsData(dataSelected, dataNotSelected, textSelectedOptions, textNotSelectedOptions) {
        let dataBodySelected = {
            key: textSelectedOptions,
            value: JSON.stringify(dataSelected)
        };
        const settings = {
            method: 'POST',
            headers: {
                'accessToken': this.props.location.state.AccessToken,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataBodySelected),
        };
        try {
            const fetchResponse = await fetch(this.props.location.state.ApiURL + "UserLogin/Key/", settings);
            await fetchResponse.json();

            await this.saveOptionsDataNotSelected(dataNotSelected, dataSelected, textNotSelectedOptions);
            // return response;
        } catch (e) {
            return e;
        }
    }

    async saveOptionsDataNotSelected(dataNotSelected, dataSelected, textNotSelectedOptions) {
        if (dataNotSelected === null) {
            dataNotSelected = [];
        }

        // const uniqueData = dataNotSelected.filter((item, index, array) =>
        //     index === array.findIndex(obj => obj.id === item.id)
        // );
        let dataBody = {
            key: textNotSelectedOptions,
            value: JSON.stringify(dataNotSelected)
        };
        const settings = {
            method: 'POST',
            headers: {
                'accessToken': this.props.location.state.AccessToken,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataBody),
        };
        try {
            const fetchResponse = await fetch(this.props.location.state.ApiURL + "UserLogin/Key/", settings);
            const response = await fetchResponse.json();

            return response;
        } catch (e) {
            return e;
        }
    }

    formatDate() {
        let time = new Date();
        let day = (time.getDate() < 10 ? "0" + time.getDate() : time.getDate());
        let month = ((time.getMonth() + 1) < 10 ? "0" + (time.getMonth() + 1) : (time.getMonth() + 1));
        let hour = (time.getHours() < 10 ? "0" + time.getHours() : time.getHours());
        let minutes = (time.getMinutes() < 10 ? "0" + time.getMinutes() : time.getMinutes());
        let seconds = (time.getSeconds() < 10 ? "0" + time.getSeconds() : time.getSeconds());
        return day + "/" + month + "/" + time.getFullYear() + " " + hour + ":" + minutes + ":" + seconds;
    }

    redirectToLogin() {
        this.props.history.push({
            pathname: '/'
        });
    }

    DateTimeToTicks(date) {
        var ticks = ((date * 10000) + 621355968000000000);
        return ticks;
    }

    getValue = (value) => {
        this.setState({
            startDate: value,
            endDate: value,
            loading: true
        }, async () => {
            let company = this.props.companyName;
            company = company.toLocaleLowerCase();
            await this.getOptionsDataSelected(company, "Seleccionadas", "NoSeleccionadas");
        });

    }

    formatGroupLabel = (data) => (
        <div style={groupStyles}>
            <span>{data.label}</span>
            <span style={groupBadgeStyles}>{data.options.length}</span>
        </div>
    );

    handleChange = (values) => {
        this.setState({ valueTracto: values });
    };

    handleOnChange(newSelection) {
        if (newSelection === null) {
            newSelection = [];
        }
        this.updateTableFiltered(newSelection);
    }

    handleOnChangeTracto(newSelection) {
        let newArrayFilter = [];

        if (newSelection === null) {
            newSelection = [];
        }

        for (let i = 0; i < newSelection.length; i++) {
            newArrayFilter.push(newSelection[i].label);
        }
        if (newArrayFilter.length >= 1) {

            let tmp = [];

            for (let i = 0; i < newSelection.length; i++) {
                tmp.push(newSelection[i].label);
            }

            const newState = {
                ...this.state.globalFilter,
                combo1: tmp
            }

            this.setState({
                globalFilter: newState,
                valueTracto: newSelection
            }, () => {
                this.filterGlobal(true);
            });
        } else {
            const newState = {
                ...this.state.globalFilter,
                combo1: []
            }
            this.setState({
                globalFilter: newState,
                valueTracto: []
            }, () => {
                this.filterGlobal(true);
            });
        }



    }

    handleOnChangeTolva(newSelection) {
        let newArrayFilter = [];

        if (newSelection === null) {
            newSelection = [];
        }

        for (let i = 0; i < newSelection.length; i++) {
            newArrayFilter.push(newSelection[i].label);
        }
        if (newArrayFilter.length >= 1) {

            let tmp = [];

            for (let i = 0; i < newSelection.length; i++) {
                tmp.push(newSelection[i].label);
            }

            const newState = {
                ...this.state.globalFilter,
                combo2: tmp
            }

            this.setState({
                globalFilter: newState,
                valueTolva: newSelection
            }, () => {
                this.filterGlobal(true);
            });
        } else {
            const newState = {
                ...this.state.globalFilter,
                combo2: []
            }
            this.setState({
                globalFilter: newState,
                valueTolva: []
            }, () => {
                this.filterGlobal(true);
            });
        }

    }

    handleOnChangePlantel(newSelection) {
        let newArrayFilter = [];

        if (newSelection === null) {
            newSelection = [];
        }

        for (let i = 0; i < newSelection.length; i++) {
            newArrayFilter.push(newSelection[i].label);
        }
        if (newArrayFilter.length >= 1) {

            let tmp = [];

            for (let i = 0; i < newSelection.length; i++) {
                tmp.push(newSelection[i].label);
            }

            const newState = {
                ...this.state.globalFilter,
                combo3: tmp
            }

            this.setState({
                globalFilter: newState,
                valuePlantel: newSelection
            }, () => {
                this.filterGlobal(true);
            });
        } else {
            const newState = {
                ...this.state.globalFilter,
                combo3: []
            }
            this.setState({
                globalFilter: newState,
                valuePlantel: []
            }, () => {
                this.filterGlobal(true);
            });
        }
    }

    handleOnchangeEstado(newSelection) {
        let newArrayFilter = [];

        if (newSelection === null) {
            newSelection = [];
        }

        for (let i = 0; i < newSelection.length; i++) {
            newArrayFilter.push(newSelection[i].label);
        }
        if (newArrayFilter.length >= 1) {

            let tmp = [];

            for (let i = 0; i < newSelection.length; i++) {
                tmp.push(newSelection[i].label);
            }

            const newState = {
                ...this.state.globalFilter,
                combo4: tmp
            }

            this.setState({
                globalFilter: newState,
                valueState: newSelection
            }, () => {
                this.filterGlobal(true);
            });
        } else {
            const newState = {
                ...this.state.globalFilter,
                combo4: []
            }
            this.setState({
                globalFilter: newState,
                valueState: []
            }, () => {
                this.filterGlobal(true);
            });
        }
    }

    handleOnChangeDriver(newSelection) {
        let newArrayFilter = [];

        if (newSelection === null) {
            newSelection = [];
        }

        for (let i = 0; i < newSelection.length; i++) {
            newArrayFilter.push(newSelection[i].label);
        }
        if (newArrayFilter.length >= 1) {

            let tmp = [];

            for (let i = 0; i < newSelection.length; i++) {
                tmp.push(newSelection[i].label);
            }

            const newState = {
                ...this.state.globalFilter,
                combo6: tmp
            }

            this.setState({
                globalFilter: newState,
                valueDriver: newSelection
            }, () => {
                this.filterGlobal(true);
            });
        } else {
            const newState = {
                ...this.state.globalFilter,
                combo6: []
            }
            this.setState({
                globalFilter: newState,
                valueDriver: []
            }, () => {
                this.filterGlobal(true);
            });
        }
    }

    handleOnChangeOrgVentas(newSelection) {
        let newArrayFilter = [];

        if (newSelection === null) {
            newSelection = [];
        }

        for (let i = 0; i < newSelection.length; i++) {
            newArrayFilter.push(newSelection[i].label);
        }
        if (newArrayFilter.length >= 1) {

            let tmp = [];

            for (let i = 0; i < newSelection.length; i++) {
                tmp.push(newSelection[i].label);
            }

            const newState = {
                ...this.state.globalFilter,
                combo5: tmp
            }

            this.setState({
                globalFilter: newState,
                valueOrgVentas: newSelection
            }, () => {
                this.filterGlobal(true);
            });
        } else {
            const newState = {
                ...this.state.globalFilter,
                combo5: []
            }
            this.setState({
                globalFilter: newState,
                valueOrgVentas: []
            }, () => {
                this.filterGlobal(true);
            });
        }
    }

    handleOnChangeDescription = (event) => {
        let textToBeSearch = event.target.value;
        let newArrayFilter = [];
        if (textToBeSearch === null) {
            textToBeSearch = "";
        }

        newArrayFilter.push(textToBeSearch);

        if (newArrayFilter.length >= 1) {

            let tmp = [];

            tmp.push(textToBeSearch);

            const newState = {
                ...this.state.globalFilter,
                combo7: tmp
            }

            this.setState({
                globalFilter: newState,
                valueTextToBeSearch: tmp
            }, () => {
                this.filterGlobal(true);
            });
        } else {
            const newState = {
                ...this.state.globalFilter,
                combo7: []
            }
            this.setState({
                globalFilter: newState,
                valueOrgVentas: []
            }, () => {
                this.filterGlobal(true);
            });
        }

    }

    updateTableFiltered() {
        const { globalFilter } = this.state;
        let newArray = [];
        let tmp = [];
        // let tds = "";
        if (globalFilter.length > 0) {
            for (let i = 0; i < globalFilter.length; i++) {
                this.state.copyServices.filter(function (v, j) {
                    if (v["Cabeza"] === globalFilter[i]) {
                        tmp.push(v);
                    }
                    if (v["Remolque"] === globalFilter[i]) {
                        tmp.push(v);
                    }
                    if (v["Destino"] === globalFilter[i]) {
                        tmp.push(v);
                    }
                    if (v["OrgVentas"] === globalFilter[i]) {
                        tmp.push(v);
                    }
                    if (v["Estado"] === globalFilter[i]) {
                        tmp.push(v);
                    }
                    if (v["Conductor"] === globalFilter[i]) {
                        tmp.push(v);
                    }
                    if (v["Descripcion"].match(globalFilter[i])) {
                        tmp.push(v);
                    }
                    return v;
                });
            }
            // newArray.push(result[0]);
            for (let j = 0; j < tmp.length; j++) {
                newArray.push(tmp[j]);
            }
            newArray = this.RemoveDuplicateServices(newArray);
            let rows = this.buildTableData(this.state.newHeaders, newArray);

            if (document.getElementById('output') !== null) {
                let output = document.getElementById('output')
                output.innerHTML = rows;
            }
        } else {
            newArray = this.buildArrayListServices(this.state.copyServices, this.state.newHeaders);
            let rows = this.buildTableData(this.state.newHeaders, this.state.copyServices);

            if (document.getElementById('output') !== null) {
                let output = document.getElementById('output')
                output.innerHTML = rows;
            }
        }

        return newArray;
    }

    UpdateTableFilteredInputText() {
        const { globalFilter } = this.state;
        let newArray = [];
        let tmp = [];
        // let tds = "";

        if (globalFilter.length > 0) {
            for (let i = 0; i < globalFilter.length; i++) {
                this.state.copyServices.filter(function (v, j) {
                    if (v.Descripcion.match(globalFilter[i])) {
                        tmp.push(v);
                    }
                    return v;
                });
            }
            for (let j = 0; j < tmp.length; j++) {
                newArray.push(tmp[j]);
            }
            let rows = this.buildTableData(this.state.newHeaders, newArray);

            if (document.getElementById('output') !== null) {
                let output = document.getElementById('output')
                output.innerHTML = rows;
            }
        } else {
            newArray = this.state.copyServices;
            let rows = this.buildTableData(this.state.newHeaders, newArray);

            if (document.getElementById('output') !== null) {
                let output = document.getElementById('output')
                output.innerHTML = rows;
            }
        }
        newArray = this.RemoveDuplicateServices(newArray);
        return newArray;
    }

    RemoveDuplicateServices(array) {
        let hash = {};
        let uniqueArray = [];
        uniqueArray = array.filter(o => hash[o.Remolque] ? false : hash[o.Remolque] = true);

        return uniqueArray;
    }

    RemoveDuplicates(array) {
        let hash = {};
        let uniqueArray = [];
        uniqueArray = array.filter(o => hash[o.label] ? false : hash[o.label] = true);

        return uniqueArray;
    }

    customRenderSelect(list, value) {
        let selectedVals = "";
        if (value !== null) {
            selectedVals = value.map((x) => x.value);
        }
        const hiddenOptions =
            selectedVals.length > 2 ? selectedVals.slice(0, 2) : [];
        const options = list.filter(
            (x) => !hiddenOptions.includes(x.value)
        );
        return options;
    }

    onClickToggle = () => {
        this.setState({
            viewFilters: !this.state.viewFilters
        });
    }

    onClickHandleModal() {
        this.setState({
            modalShow: !this.state.modalShow
        })
    }

    makeStyles = (theme) => ({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            backgroundColor: '#fff',
            border: '2px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
        }
    })

    onDragEnd = result => {
        // TODO reorder our column
        let tmp = [];
        let newHeaders = [];
        let headersNotSelected = [];
        const { destination, source, draggableId } = result;

        if (!destination) {
            return;
        }

        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        const start = this.state.listTableTranslate.columns[source.droppableId];
        const finish = this.state.listTableTranslate.columns[destination.droppableId];

        if (start === finish) {
            const newHeaderIds = Array.from(start.headersIds);
            newHeaderIds.splice(source.index, 1);
            newHeaderIds.splice(destination.index, 0, draggableId);

            const newColumn = {
                ...start,
                headersIds: newHeaderIds
            }
            const newState = {
                ...this.state.listTableTranslate,
                columns: {
                    ...this.state.listTableTranslate.columns,
                    [newColumn.id]: newColumn,
                }
            };

            for (const key in this.state.listTableTranslate.headers) {
                if (this.state.listTableTranslate.headers.hasOwnProperty(key)) {
                    tmp.push(this.state.listTableTranslate.headers[key]);
                }
            }

            for (let i = 0; i < newState.columns["column-2"].headersIds.length; i++) {
                tmp.filter(function (v, j) {
                    if (v["id"] === newState.columns["column-2"].headersIds[i]) {
                        newHeaders.push(v);
                    }
                    return v;
                });
            }
            this.setState({
                listTableTranslate: newState,
                newHeaders: newHeaders,
            });
            return;
        }

        //moving from one list to another
        const startHeaderIds = Array.from(start.headersIds);
        startHeaderIds.splice(source.index, 1);

        const newStart = {
            ...start,
            headersIds: startHeaderIds
        };

        const finishHeaderIds = Array.from(finish.headersIds);
        finishHeaderIds.splice(destination.index, 0, draggableId);

        const newFinish = {
            ...finish,
            headersIds: finishHeaderIds
        }
        const newState = {
            ...this.state.listTableTranslate,
            columns: {
                ...this.state.listTableTranslate.columns,
                [newStart.id]: newStart,
                [newFinish.id]: newFinish,
            }
        };

        for (const key in this.state.listTableTranslate.headers) {
            if (this.state.listTableTranslate.headers.hasOwnProperty(key)) {
                tmp.push(this.state.listTableTranslate.headers[key]);
            }
        }

        for (let i = 0; i < newState.columns["column-2"].headersIds.length; i++) {
            tmp.filter(function (v, j) {
                if (v["id"] === newState.columns["column-2"].headersIds[i]) {
                    newHeaders.push(v);
                }
                return v;
            });
        }

        for (let i = 0; i < newState.columns["column-1"].headersIds.length; i++) {
            tmp.filter(function (v, j) {
                if (v["id"] === newState.columns["column-1"].headersIds[i]) {
                    headersNotSelected.push(v);
                }
                return v;
            });
        }



        this.setState({
            listTableTranslate: newState,
            newHeaders: newHeaders,
            headersNotSelected: headersNotSelected
            // services: updatedServices
        });
    }

    copyObjectProps = (source, keys) => {
        let newObject = {}
        keys.forEach(function (key) {
            newObject[key] = source[key]
        })
        return newObject
    }

    buildArrayListExcel(services, headers) {
        let excelServicesList = [];
        for (let i = 0; i < services.length; i++) {
            var jsonData = {};
            headers.forEach(function (column) {
                let label = column.label;
                // label = label.charAt(0).toLowerCase() + label.slice(1);
                if (column.label === "Productos") {
                    for (let j = 0; j < services[i].Productos.length; j++) {
                        if (services[i].Productos !== undefined) {
                            if (jsonData.hasOwnProperty("Productos")) {
                                jsonData[column.content] += services[i].Productos[j].N + " " + services[i].Productos[j].C + " " + services[i].Productos[j].U;
                            } else {
                                jsonData[column.content] = services[i].Productos[j].N + " " + services[i].Productos[j].C + " " + services[i].Productos[j].U + ", ";
                            }
                        }
                    }
                } else {
                    jsonData[column.content] = services[i][label];
                }
            });
            excelServicesList.push(jsonData);
        }
        return excelServicesList;
    }

    buildArrayListServices(services, headers) {
        let excelServicesList = [];
        for (let i = 0; i < services.length; i++) {
            var jsonData = {};
            headers.forEach(function (column) {
                let label = column.label;
                jsonData[column.label] = services[i][label];
            });
            excelServicesList.push(jsonData);
        }
        return excelServicesList;
    }

    handleStartDateChange = (value) => {
        //this.setState({ startDate: value });
        this.setState({
            startDate: value,

        });
    };

    handleEndDateChange = (value) => {
        this.setState({ endDate: value });
    };

    handleCheckboxChange = (event) => {
        //this.setState({ showDateFilters: event.target.checked });
        this.setState(
            (prevState) => ({
                showDateFilters: !prevState.showDateFilters,
                showSnackbarHistorical: !prevState.showDateFilters,
            }),
            () => {
                // Aquí es donde envuelves en una función async
                const updateState = async () => {
                    if (this.state.showDateFilters) {
                        this.stopTimer();
                    } else {
                        this.setState(
                            {
                                startDate: new Date(),
                                endDate: new Date(),
                                loading: true
                            },
                            async () => {
                                this.startTimer();
                                let company = this.props.companyName;
                                company = company.toLocaleLowerCase();
                                await this.getOptionsDataSelected(company, "Seleccionadas", "NoSeleccionadas");
                            }
                        );
                    }
                };
                updateState(); // Llamas a la función async
            }
        );
    };

    hideModalMobile() {
        this.setState({
            modalShow: false
        })
    }

    handleSubmit = async () => {
        this.setState({ loading: true });

        const { startDate, endDate } = this.state;
        let company = this.props.companyName.toLowerCase();

        // Realizar la llamada a la API con el rango de fechas
        await this.getOptionsDataSelected(company, "Seleccionadas", "NoSeleccionadas", startDate, endDate);

        this.setState({ loading: false });
    };
    render() {
        const { valueTracto, valueTolva } = this.state;
        const { t } = this.props;
        // let optionsTracto = this.customRenderSelect(this.state.tractoList, valueTracto);
        // let optionsTolva = this.customRenderSelect(this.state.tolvaList, valueTolva);
        // <MDBTableBody style={{ display: this.state.loading ? "none" : "table-header-group" }}></MDBTableBody>
        // if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
        //     this.hideModalMobile();
        // }
        return (
            <div>
                {this.props.isAuthenticated === false ? this.redirectToLogin() :
                    <div>
                        <h2 style={{ textAlign: "center", color: "#bf2349" }}>{t("layerListServices.title")}</h2>
                        <div className="tableContainer">
                            {/* <div></div> */}
                            <div>
                                <ExportCSV csvData={this.state.excelServices} fileName={fileName} />
                            </div>
                            <div style={{ marginBottom: "30px" }}>
                                <button onClick={() => this.getData(this.props.companyName.toLowerCase(), this.state.headersSelected, this.state.headersNotSelectedObject, this.state.vehiclesListChangeMassiveState, this.state.driversListChangeMassiveState)} className="btn btn-default btnExport">Actualizar</button>
                            </div>


                            <div className="checkbox-container">
                                <label className="checkbox-label">
                                    <Checkbox
                                        type="checkbox"
                                        checked={this.state.showDateFilters}
                                        onChange={this.handleCheckboxChange}
                                        className="checkbox-input"
                                    />
                                    Filtrar por rango de fechas
                                </label>
                            </div>

                            {this.state.showDateFilters ? (
                                <div>
                                    <div className="datePicker" style={{ marginBottom: "10px", float: "right" }}>
                                        <label>Fecha de fin:</label>
                                        <DatePicker
                                            onChange={this.handleEndDateChange}
                                            value={this.state.endDate}
                                            format={"dd-MM-y"}
                                            maxDate={new Date()}
                                            clearIcon={null}
                                            locale={"es-ES"}
                                        />
                                    </div>
                                    <div className="datePicker" style={{ marginBottom: "10px", float: "right" }}>
                                        <label>Fecha de inicio:</label>
                                        <DatePicker
                                            onChange={this.handleStartDateChange}
                                            value={this.state.startDate}
                                            format={"dd-MM-y"}
                                            maxDate={new Date()}
                                            clearIcon={null}
                                            locale={"es-ES"}
                                        />
                                    </div>

                                </div>
                            ) :
                                <div>
                                    <div className="datePicker" style={{ float: "right" }}>
                                        <DatePicker
                                            onChange={this.getValue}
                                            value={this.state.startDate}
                                            format={"dd/MM/y"}
                                            maxDate={new Date()}
                                            clearIcon={null}
                                            locale={"es-ES"}
                                        />
                                    </div>
                                </div>}
                        </div>
                        {
                            this.state.showDateFilters &&
                            //  <div style={{ margin: "auto", color: "#505050", textAlign: "center" }}>
                            //                           <Checkbox checked={this.state.isChecked} onChange={this.IsCheckedHandle}></Checkbox>
                            //                           <label color="pink">{t("loginForm.remember")}</label>
                            //                         </div>
                            <div style={{ marginBottom: "10px", marginTop: 30 }}>
                                <button onClick={this.handleApplyFilters} className="btn btn-default btnExport" style={{ marginBottom: "10px", float: "right" }}>Aplicar fechas</button>
                            </div>
                        }

                        <div className="grid-container">
                            <div className="grid-item">
                                <FormGroup aria-label="position" style={{ width: "3%" }} row>
                                    <FormControlLabel
                                        value="start"

                                        control={<Switch checked={this.state.viewFilters ? true : false} onClick={this.onClickToggle} />}
                                        label="Filtros"
                                        labelPlacement="start"
                                    />
                                </FormGroup>
                            </div>
                        </div>
                        <div className="slide-down slide-up" style={{ display: this.state.viewFilters ? "inline-block" : "none", width: "100%" }}>
                            <div className="grid-container" style={{ width: "100%" }}>
                                <div className="grid-item">
                                    <Select
                                        value={valueTracto}
                                        onChange={this.handleOnChangeTracto.bind(this)}
                                        classNamePrefix="select"
                                        name="Matricula"
                                        placeholder={this.state.comboBoxText.Cabeza}
                                        options={this.state.groupedOptionsTractoList}
                                        formatGroupLabel={this.formatGroupLabel}
                                        isClearable={true}
                                        isMulti
                                        hideSelectedOptions={false}
                                        isSearchable={true}
                                    // components={{ ValueContainer }}
                                    />
                                </div>
                                <div className="grid-item">
                                    <Select
                                        value={valueTolva}
                                        onChange={this.handleOnChangeTolva.bind(this)}

                                        name="SemiRemolques"
                                        placeholder={this.state.comboBoxText.Remolque}
                                        options={this.state.groupedOptionsTolvaList}
                                        formatGroupLabel={this.formatGroupLabel}
                                        isClearable={true}
                                        isMulti
                                        hideSelectedOptions={false}
                                        isSearchable={true}
                                    // components={{ ValueContainer }}
                                    />
                                </div>
                                <div className="grid-item">
                                    <Select
                                        value={this.state.valuePlantel}
                                        onChange={this.handleOnChangePlantel.bind(this)}
                                        name="Plantel"
                                        placeholder={this.state.comboBoxText.Cliente}
                                        options={this.state.groupedOptionsPlantelList}
                                        formatGroupLabel={this.formatGroupLabel}
                                        isClearable={true}
                                        isMulti
                                        hideSelectedOptions={false}
                                        isSearchable={true}
                                    // components={{ ValueContainer }}
                                    />
                                </div>
                                <div className="grid-item">
                                    <Select
                                        value={this.state.valueState}
                                        onChange={this.handleOnchangeEstado.bind(this)}
                                        name="Estados"
                                        placeholder="Estado"
                                        options={this.state.groupedOptionsStateList}
                                        formatGroupLabel={this.formatGroupLabel}
                                        isClearable={true}
                                        isMulti
                                        hideSelectedOptions={false}
                                        isSearchable={true}
                                    // components={{ ValueContainer }}
                                    />
                                </div>
                                <div className="grid-item">
                                    <Select
                                        value={this.state.valueOrgVentas}
                                        onChange={this.handleOnChangeOrgVentas.bind(this)}
                                        name="OrgVentas"
                                        placeholder={this.state.comboBoxText.OrgVentas}
                                        options={this.state.groupedOptionsOrgVentasList}
                                        formatGroupLabel={this.formatGroupLabel}
                                        isClearable={true}
                                        isMulti
                                        hideSelectedOptions={false}
                                        isSearchable={true}
                                    // components={{ ValueContainer }}
                                    />
                                </div>

                                <div className="grid-item">
                                    <Select
                                        value={this.state.valueDriver}
                                        onChange={this.handleOnChangeDriver.bind(this)}
                                        name="Conductor"
                                        placeholder="Conductor"
                                        options={this.state.groupedOptionsDriverList}
                                        formatGroupLabel={this.formatGroupLabel}
                                        isClearable={true}
                                        isMulti
                                        hideSelectedOptions={false}
                                        isSearchable={true}
                                    // components={{ ValueContainer }}
                                    />
                                </div>
                                <div className="grid-item" >
                                    <TextField
                                        id="outlined-basic"
                                        label={this.state.comboBoxText.Descripcion}
                                        variant="outlined"
                                        size="small"
                                        onChange={this.handleOnChangeDescription.bind(this)}
                                    />
                                </div>
                            </div>

                        </div>

                        <button style={{ display: this.state.modalMobile === true ? "block" : "none" }} className="btnModal" onClick={this.onClickHandleModal.bind(this)}>Visualización  <img src={imageFilter} alt="iconFilter" style={{ width: "10%", marginLeft: "5px" }}></img></button>

                        <div className="updatedTime">
                            <p>Última actualización: <b>{this.state.time}</b></p>
                        </div>

                        <Modal
                            aria-labelledby="transition-modal-title"
                            aria-describedby="transition-modal-description"
                            className="modalCustom"
                            open={this.state.modalShow}
                            onClose={this.onClickHandleModal.bind(this)}
                            closeAfterTransition
                            BackdropComponent={Backdrop}
                            BackdropProps={{
                                timeout: 500,
                            }}

                        >
                            <Fade in={this.state.modalShow}>

                                <div className="paper">

                                    {
                                        <DragDropContext onDragEnd={this.onDragEnd}>
                                            {
                                                this.state.listTableTranslate !== undefined && this.state.listTableTranslate.columnOrder !== undefined ?
                                                    this.state.listTableTranslate.columnOrder.map(columnId => {
                                                        const column = this.state.listTableTranslate.columns[columnId];
                                                        const headers = column.headersIds.map(headerId => this.state.listTableTranslate.headers[headerId]);
                                                        return <Column key={column.id} column={column} headers={headers} />
                                                    })
                                                    :
                                                    null
                                            }

                                        </DragDropContext>

                                    }
                                    <p></p>

                                    <button style={{ height: "34px", width: "243px", marginLeft: "8px", borderRadius: "3px", marginBottom: "30px" }} className="btn btn-default" onClick={this.filterGlobal.bind(this)}>Aceptar</button>
                                </div>
                            </Fade>
                        </Modal>


                        {this.state.loading ?
                            <div className="loadingSpinner">
                                <BeatLoader css={spinnerCss} size={15} margin={4} color={"#bf2349"} />
                            </div>
                            :
                            <div id="tableServicesId" style={{ marginBottom: 65 }}>
                                <MDBDataTable

                                    name="table"
                                    striped
                                    scrollY={true}
                                    bordered={true}
                                    paging={false}
                                    searching={false}
                                    data={this.state.listDataTable}
                                    className="tableServices"
                                    responsive={true}
                                    sortable={false}
                                    maxHeight={"70vh"}

                                    small
                                // onSort={this.handleOnSort.bind(this)}

                                >
                                </MDBDataTable  >
                            </div>


                        }
                    </div>

                }
                <Snackbar

                />
                <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    open={this.state.snackbarOpen}
                    autoHideDuration={6000} // Se cierra automáticamente en 3 segundos
                    onClose={this.handleSnackbarClose}
                >
                    <Alert onClose={this.handleSnackbarClose} severity="warning">
                        {this.state.snackbarMessage}
                    </Alert>
                </Snackbar>

                {/* Snackbar con alert */}
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={this.state.showSnackbarHistorical}

                >
                    <Alert severity="info">
                        {this.state.showSnackbarHistoricalMessage}
                    </Alert>
                </Snackbar>
            </div>
        )
    }
}

export default withRouter(withTranslation()(taskOrderList));

// const ValueContainer = ({ children, getValue, ...props }) => {
//     let maxToShow = 2;
//     var length = getValue().length;
//     let displayChips = React.Children.toArray(children).slice(0, maxToShow);
//     let title = getValue()
//         .slice(maxToShow)
//         .map((x) => x.label)
//         .join(", ");
//     let shouldBadgeShow = length > maxToShow;
//     let displayLength = length - maxToShow;
//     return (
//         <components.ValueContainer {...props}>
//             {!props.selectProps.inputValue && displayChips}
//             {!!shouldBadgeShow && (
//                 <div className="root" title={title}>
//                     {/* {`+ ${displayLength} Opcione${length !== 1 ? "s" : ""} seleccionadas`} */}
//                     {`+ ${displayLength} `}
//                 </div>
//             )}
//         </components.ValueContainer>
//     );
// };
