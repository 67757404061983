import React from 'react';
import clsx from 'clsx';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import { Route, Link } from "react-router-dom";
import HistoryOutlinedIcon from '@material-ui/icons/HistoryOutlined';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import AccessTimeOutlinedIcon from '@material-ui/icons/AccessTimeOutlined';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import EventNoteOutlinedIcon from '@material-ui/icons/EventNoteOutlined';
import MapOutlinedIcon from '@material-ui/icons/MapOutlined';
import PowerSettingsNewOutlinedIcon from '@material-ui/icons/PowerSettingsNewOutlined';
import Mapa from '../../map/Map'
import { withRouter } from 'react-router-dom';
import Logo from '../../common/img/logo.png';
import { getSessionCookie } from '../../global/cookies/cookies';

import InstaladoresCheckControl from '../../Instaladores/InstaladoresCheckControl';
import Cookies from "js-cookie";
import DataTablePlaning from '../../disa/planning/dataTablePlaning';
import TaskOrderList from '../../services/realTime/taskOrderList';
import TableChartOutlinedIcon from '@material-ui/icons/TableChartOutlined';
import DataTableIncidentManagement from '../../disa/IncidentManagement/DataTableIncidentManagement';
import Container from '@material-ui/core/Container';

import TemporalAccessList from '../../users/temporalAccessFolder/TemporalAccessList';

import logo4gflota from '../../../containers/img/4gflota.png';
import ControlTemporalAccessGroup from '../../users/temporalAccessFolder/ControlTemporalAccessGroup';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import TaskOrdersRoutes from '../../Instaladores/InfoCRM/TaskOrdersRoutes';
import AlertsWarnings from '../../AlertsWarnings/AlertsWarnings';
import NotificationImportantOutlinedIcon from '@material-ui/icons/NotificationImportantOutlined';
import SondasBT from '../../SondasBT/SondasBT';
import BuzonSugerencias from '../../BuzonSugerencias/BuzonSugerencias';
import RecurringPois from '../../RecurringPois/RecurringPois';

// const history = createBrowserHistory();
const styles = theme => ({

    root: {
        flexGrow: 1,
        height: `calc(100% - 65px)`
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
    listMenu: {
        '& .MuiListItemIcon-root': {
            minWidth: 35
        }
    }
});



class DrawerComponent extends React.Component {

    constructor() {
        super(...arguments);
        this.state = {
            open: false,
            isPermissionActive: false,
            resultPermissionUser: false,
            instalationPermission: false,
            company: this.props.companyName,
            accessToken: this.props.accessToken

        }
    }

    async componentDidMount() {
        // pAdministracionUsuarios
        if (this.isEmpty(getSessionCookie()) || this.props.isAuthenticated === false || this.props.isAuthenticated === undefined) {
            this.redirectToLogin();
        }
        // else if (this.isEmpty(getSessionCookie())) {

        //     this.redirectToLogin();
        else {

            let isPermission = this.props.location.state.userDataInfo.accessPermision;
            var result = isPermission.indexOf("pD4service");
            var permissionUser = isPermission.indexOf("pAdministracionUsuarios");
            let resultPermissionUser = false;
            let instalationPermission = isPermission.indexOf("pAdministracionConfiguradorVehiculos");
            let isPermisionInstalation = false;



            if (permissionUser !== -1) {
                resultPermissionUser = true;
            }

            if (instalationPermission !== -1) {
                isPermisionInstalation = true;
            }

            if (result === -1) {
                this.setState({
                    isPermissionActive: false,
                    resultPermissionUser: resultPermissionUser,
                    instalationPermission: isPermisionInstalation,
                    company: this.props.companyName,
                    accessToken: this.props.location.state.AccessToken,
                }, () => {

                    if (this.props.location.state.notification) {
                        this.props.userHasAuthenticated(true);
                        this.props.history.push({
                            pathname: '/dashboard/alertas-avisos',
                            state: {
                                locale: this.props.location.state.locale,
                                userDataInfo: this.props.location.state.userDataInfo,
                                ApiURL: this.props.location.state.ApiURL,
                                AccessToken: this.props.location.state.userDataInfo.accessToken,
                                notification: this.props.location.state.notification,
                                dateNotification: this.props.location.state.dateNotification
                            },
                            isAuthenticated: this.props.isAuthenticated,
                            companyName: this.props.companyName,
                            accessToken: this.props.location.state.AccessToken,
                            origin: false

                        });

                    } else if (this.props.companyName.toLowerCase() !== "instalaciones") {
                        this.props.userHasAuthenticated(true);
                        this.props.history.push({
                            pathname: '/dashboard/mapa',
                            state: {
                                locale: this.props.location.state.locale,
                                userDataInfo: this.props.location.state.userDataInfo,
                                ApiURL: this.props.location.state.ApiURL,
                                AccessToken: this.props.location.state.userDataInfo.accessToken
                            },
                            isAuthenticated: this.props.isAuthenticated,
                            companyName: this.props.companyName,
                            accessToken: this.props.location.state.AccessToken,
                            origin: false

                        });
                    } else {
                        // console.log(this.props.location.state.AccessToken);
                        this.props.history.push({
                            pathname: '/dashboard/instaladores',
                            state: {
                                locale: this.props.location.state.locale,
                                userDataInfo: this.props.location.state.userDataInfo,
                                ApiURL: this.props.location.state.ApiURL,
                                AccessToken: this.props.location.state.userDataInfo.accessToken
                            },
                            accessToken: this.props.location.state.AccessToken,
                            isAuthenticated: this.props.isAuthenticated,
                            companyName: this.props.companyName

                        });
                    }


                });
            } else {

                this.setState({
                    isPermissionActive: true,
                    company: this.state.company,
                    resultPermissionUser: resultPermissionUser,
                    isPermisionInstalation: isPermisionInstalation,
                    accessToken: this.props.location.state.AccessToken,
                }, () => {
                    // console.log(this.props.location.state.AccessToken);
                    if (this.props.location.state.notification) {
                        this.props.userHasAuthenticated(true);
                        this.props.history.push({
                            pathname: '/dashboard/alertas-avisos',
                            state: {
                                locale: this.props.location.state.locale,
                                userDataInfo: this.props.location.state.userDataInfo,
                                ApiURL: this.props.location.state.ApiURL,
                                AccessToken: this.props.location.state.userDataInfo.accessToken,
                                notification: this.props.location.state.notification,
                                dateNotification: this.props.location.state.dateNotification
                            },
                            isAuthenticated: this.props.isAuthenticated,
                            companyName: this.props.companyName,
                            accessToken: this.props.location.state.AccessToken,
                            origin: false
                        });
                    } else
                        if (this.props.companyName.toLowerCase() !== "instalaciones") {
                            this.props.userHasAuthenticated(true);
                            this.props.history.push({
                                pathname: '/dashboard/mapa/',
                                state: {
                                    locale: this.props.location.state.locale,
                                    userDataInfo: this.props.location.state.userDataInfo,
                                    ApiURL: this.props.location.state.ApiURL,
                                    AccessToken: this.props.location.state.userDataInfo.accessToken
                                },
                                isAuthenticated: this.props.isAuthenticated,
                                companyName: this.props.companyName,
                                accessToken: this.props.location.state.AccessToken,
                                origin: false

                            });
                        } else {
                            // console.log(this.props.location.state.AccessToken);
                            this.props.history.push({
                                pathname: '/dashboard/instaladores/',
                                state: {
                                    locale: this.props.location.state.locale,
                                    userDataInfo: this.props.location.state.userDataInfo,
                                    ApiURL: this.props.location.state.ApiURL,
                                    AccessToken: this.props.location.state.userDataInfo.accessToken
                                },
                                isAuthenticated: this.props.isAuthenticated,
                                companyName: this.props.companyName,
                                accessToken: this.props.location.state.AccessToken,

                            });
                        }
                });
            }

        }
    }

    goToDataGridPlaning = async (e) => {
        e.preventDefault();
        this.props.history.push({
            pathname: '/Planning/' + this.props.companyName,
            state: {
                ApiURL: this.props.location.state.ApiURL,
                AccessToken: this.props.location.state.userDataInfo.accessToken
            }
        });
    }

    isEmpty(obj) {
        for (var prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                return false;
            }
        }

        return JSON.stringify(obj) === JSON.stringify({});
    }

    redirectToLogin() {
        this.props.userHasAuthenticated(false);
        Cookies.remove("session");

        let FCMToken = localStorage.getItem("FCMToken");

        if (FCMToken !== null) {
            localStorage.removeItem("FCMToken");
            this.props.history.push({
                pathname: '/token/' + FCMToken,
                state: {
                    logout: true
                }
            });
        } else {
            this.props.history.push({
                pathname: '/'
            });
        }


    }

    handleDrawerOpen = () => {
        // console.log("entra");
        this.setState({
            open: !this.state.open
        })
    }

    onItemClick = title => () => {
        // setTitle(title);
        // setDrawer(variant === 'temporary' ? false : true);
        // setDrawer(!drawer);
        this.setState({
            open: this.state.open
        })
    }

    goToTemporalAccess = async (accessToken, locale) => {
        // this.props.userHasAuthenticated(true);
        // console.log("entra");
        this.props.history.push({
            pathname: '/dashboard/accesotemporal/',
            //pathname: '/dashboard/sondas-bt/',
            //pathname: '/dashboard/buzon-sugerencias/',
            //pathname: '/dashboard/pois-recurrentes/',
            state: {
                locale: this.props.location.state.locale,
                userDataInfo: this.props.location.state.userDataInfo,
                ApiURL: this.props.location.state.ApiURL,
                AccessToken: this.props.location.state.userDataInfo.accessToken
            }
        });
    }
    goToAlertsAndWarnings = async (accessToken, locale) => {
        // this.props.userHasAuthenticated(true);
        // console.log("entra");
        this.onCloseDrawer();
        this.props.history.push({
            pathname: '/dashboard/alertas-avisos',
            state: {
                locale: this.props.location.state.locale,
                userDataInfo: this.props.location.state.userDataInfo,
                ApiURL: this.props.location.state.ApiURL,
                AccessToken: this.props.location.state.userDataInfo.accessToken,
                dateNotification: this.props.location.state.dateNotification
            }
        });
    }

    testOnclickList = async () => {
        setTimeout(() => {
            this.props.history.push({
                pathname: '/dashboard/añadir-configuracion-dispositivos/',

            });
        }, 3000);
        // console.log("entra");

    }

    listInstaladores = (anchor) => {
        const { t, classes } = this.props;

        return (
            <div
                className={clsx(this.props.classes.list, {
                    [this.props.classes.fullList]: anchor === 'right' || anchor === 'left',
                })}
                role="presentation"
            // onClick={toggleDrawer(anchor, false)}
            // onKeyDown={toggleDrawer(anchor, false)}
            >
                <div style={{ width: "200px", margin: "auto", padding: "15px" }}>
                    <img style={{ width: "150px" }} src={logo4gflota} alt="logo" />
                </div>

                {this.state.instalationPermission !== false ?
                    <List onClick={this.onCloseDrawer.bind(this)} className={classes.listMenu}>
                        <ListItem button component={Link} to={{ pathname: '/dashboard/instaladores/', state: this.props.location.state !== undefined ? this.props.location.state : "" }}
                        // to="/dashboard/mapa"
                        // onClick={onItemClick('Page 3')}
                        >

                            <ListItemIcon><CheckCircleOutlineOutlinedIcon /></ListItemIcon>
                            <ListItemText >Chequear</ListItemText>
                        </ListItem>


                        <ListItem button component={Link} to={{
                            pathname: '/dashboard/añadir',
                            state: { ...this.props.location.state, coming: undefined }
                        }}
                        // to="/dashboard/mapa"
                        // onClick={onItemClick('Page 3')}
                        >

                            <ListItemIcon><AddCircleOutlineOutlinedIcon /></ListItemIcon>
                            <ListItemText >Añadir</ListItemText>
                        </ListItem>
                        <ListItem button component={Link} to={{
                            pathname: '/dashboard/historico',
                            state: { ...this.props.location.state, coming: undefined }
                        }}
                        // to="/dashboard/mapa"
                        // onClick={onItemClick('Page 3')}
                        >

                            <ListItemIcon><HistoryOutlinedIcon /></ListItemIcon>
                            <ListItemText >Historico</ListItemText>
                        </ListItem>
                        <ListItem button component={Link} to={{
                            pathname: '/dashboard/configuracion-dispositivos/',
                            state: { ...this.props.location.state, coming: undefined }
                        }}
                        // to="/dashboard/mapa"
                        // onClick={onItemClick('Page 3')}
                        >

                            <ListItemIcon><SettingsOutlinedIcon /></ListItemIcon>
                            <ListItemText >DT Configuración</ListItemText>
                        </ListItem>
                        <ListItem button component={Link} to={{
                            pathname: '/dashboard/esquemas-canbus/',
                            state: { ...this.props.location.state, coming: undefined }
                        }}
                        // to="/dashboard/mapa"
                        // onClick={onItemClick('Page 3')}
                        >

                            <ListItemIcon><DescriptionOutlinedIcon /></ListItemIcon>
                            <ListItemText >Esquemas CANBus</ListItemText>
                        </ListItem>
                        <ListItem button component={Link} to={{
                            pathname: '/dashboard/gestion-manuales/',
                            state: { ...this.props.location.state, coming: undefined }
                        }}
                        // to="/dashboard/mapa"
                        // onClick={onItemClick('Page 3')}
                        >

                            <ListItemIcon><MenuBookIcon /></ListItemIcon>
                            <ListItemText >Gestión de manuales</ListItemText>
                        </ListItem>
                        <ListItem button component={Link} to={{
                            pathname: '/dashboard/ordenes-trabajo/',
                            state: { ...this.props.location.state, coming: undefined }
                        }}
                        // to="/dashboard/mapa"
                        // onClick={onItemClick('Page 3')}
                        >

                            <ListItemIcon><MenuBookIcon /></ListItemIcon>
                            <ListItemText >Mis OTs</ListItemText>
                        </ListItem>
                        <ListItem button onClick={this.redirectToLogin.bind(this)}
                        // to="/dashboard/mapa"
                        // onClick={onItemClick('Page 3')}
                        >

                            <ListItemIcon><PowerSettingsNewOutlinedIcon /></ListItemIcon>
                            <ListItemText >{t("menuAfterLogin.logout")}</ListItemText>
                        </ListItem>
                    </List>
                    :
                    <List onClick={this.onCloseDrawer.bind(this)} className={classes.listMenu}>
                        <ListItem button component={Link} to={{ pathname: '/dashboard/instaladores/', state: this.props.location.state !== undefined ? this.props.location.state : "" }}
                        // to="/dashboard/mapa"
                        // onClick={onItemClick('Page 3')}
                        >

                            <ListItemIcon><CheckCircleOutlineOutlinedIcon /></ListItemIcon>
                            <ListItemText >Chequear</ListItemText>
                        </ListItem>
                        <ListItem button component={Link} to={{
                            pathname: '/dashboard/historico',
                            state: { ...this.props.location.state, coming: undefined }
                        }}
                        // to="/dashboard/mapa"
                        // onClick={onItemClick('Page 3')}
                        >

                            <ListItemIcon><HistoryOutlinedIcon /></ListItemIcon>
                            <ListItemText >Historico</ListItemText>
                        </ListItem>

                        <ListItem button component={Link} to={{ pathname: '/dashboard/esquemas-canbus/', state: this.props.location.state !== undefined ? this.props.location.state : "" }}
                        // to="/dashboard/mapa"
                        // onClick={onItemClick('Page 3')}
                        >

                            <ListItemIcon><DescriptionOutlinedIcon /></ListItemIcon>
                            <ListItemText >Esquemas CANBus</ListItemText>
                        </ListItem>
                        <ListItem button component={Link} to={{ pathname: '/dashboard/gestion-manuales/', state: this.props.location.state !== undefined ? this.props.location.state : "" }}
                        // to="/dashboard/mapa"
                        // onClick={onItemClick('Page 3')}
                        >

                            <ListItemIcon><MenuBookIcon /></ListItemIcon>
                            <ListItemText >Gestión de manuales</ListItemText>
                        </ListItem>

                        <ListItem button component={Link} to={{ pathname: '/dashboard/ordenes-trabajo/', state: this.props.location.state !== undefined ? this.props.location.state : "" }}
                        // to="/dashboard/mapa"
                        // onClick={onItemClick('Page 3')}
                        >

                            <ListItemIcon><MenuBookIcon /></ListItemIcon>
                            <ListItemText >Mis OTs</ListItemText>
                        </ListItem>

                        <ListItem button onClick={this.redirectToLogin.bind(this)}
                        // to="/dashboard/mapa"
                        // onClick={onItemClick('Page 3')}
                        >

                            <ListItemIcon><PowerSettingsNewOutlinedIcon /></ListItemIcon>
                            <ListItemText >{t("menuAfterLogin.logout")}</ListItemText>
                        </ListItem>
                    </List>

                }

                <Divider />
            </div>
        )
    }

    onCloseDrawer() {
        this.setState({
            open: false
        });
    }

    list = (anchor) => {
        const { t, classes } = this.props;
        return (
            this.state.isPermissionActive === true ?
                <div
                    className={clsx(this.props.classes.list, {
                        [this.props.classes.fullList]: anchor === 'right' || anchor === 'left',
                    })}
                    role="presentation"
                // onClick={toggleDrawer(anchor, false)}
                // onKeyDown={toggleDrawer(anchor, false)}
                >
                    <div style={{ width: "200px", margin: "auto", padding: "15px" }}>
                        <img style={{ width: "150px" }} src={logo4gflota} alt="logo" />
                    </div>

                    <List onClick={this.onCloseDrawer.bind(this)} className={classes.listMenu}>
                        <ListItem button component={Link} to={{ pathname: '/dashboard/mapa', state: this.props.location.state !== undefined ? this.props.location.state : "", origin: false }}
                        // to="/dashboard/mapa"
                        // onClick={onItemClick('Page 3')}
                        >

                            <ListItemIcon><MapOutlinedIcon /></ListItemIcon>
                            <ListItemText >{t("menuAfterLogin.viewMap")}</ListItemText>
                        </ListItem>
                        {this.state.company.toLowerCase() === "disagestionlogistica" ?
                            <ListItem button component={Link} to={{
                                pathname: '/dashboard/planning',
                                state: this.props.location.state !== undefined ? this.props.location.state : "",
                                companyName: this.state.companyName
                            }}
                            // to="/dashboard/mapa"
                            // onClick={onItemClick('Page 3')}
                            >

                                <ListItemIcon><EventNoteOutlinedIcon /></ListItemIcon>
                                <ListItemText >{t("menuAfterLogin.viewPlanning")}</ListItemText>
                            </ListItem>
                            : null

                        }

                        {this.state.company.toLowerCase() === "disagestionlogistica" || this.state.company.toLowerCase() === "sanfernando" || this.state.company.toLowerCase() === "logistics" || this.state.company.toLowerCase() === "overtruck" ?
                            <ListItem onclick={this.onCloseDrawer.bind(this)} button component={Link} to={{
                                pathname: '/dashboard/taskOrderList',
                                state: this.props.location.state !== undefined ? this.props.location.state : "",
                                companyName: this.state.companyName
                            }}
                            // to="/dashboard/mapa"
                            // onClick={onItemClick('Page 3')}
                            >

                                <ListItemIcon><TableChartOutlinedIcon /></ListItemIcon>
                                <ListItemText >{t("menuAfterLogin.viewServices")}</ListItemText>
                            </ListItem>
                            : null
                        }
                        {this.state.company.toLowerCase() === "disagestionlogistica" ?
                            <ListItem onclick={this.onCloseDrawer.bind(this)} button component={Link} to={{
                                pathname: '/dashboard/incident-management',
                                state: this.props.location.state !== undefined ? this.props.location.state : "",
                                companyName: this.state.companyName
                            }}
                            // to="/dashboard/mapa"
                            // onClick={onItemClick('Page 3')}
                            >

                                <ListItemIcon><ErrorOutlineOutlinedIcon /></ListItemIcon>
                                <ListItemText >{t("menuAfterLogin.viewIncidentManagement")}</ListItemText>
                            </ListItem>
                            : null
                        }
                        {this.state.resultPermissionUser ?
                            <ListItem button onClick={this.goToTemporalAccess.bind(this, this.props.location.state.accessToken, this.props.location.state.locale)}
                            // component={Link} 
                            // to={{ pathname: '/dashboard/accesotemporal/', state: this.props.location.state !== undefined ? this.props.location.state : "" }}
                            // to="/dashboard/mapa"
                            // onClick={onItemClick('Page 3')}
                            >

                                <ListItemIcon><AccessTimeOutlinedIcon /></ListItemIcon>
                                <ListItemText >{t("menuAfterLogin.viewTemporalAccess")}</ListItemText>
                                {/* <ListItemText >POIs Recurrentes</ListItemText> */}
                            </ListItem>
                            : null
                        }
                        <ListItem button onClick={this.goToAlertsAndWarnings.bind(this, this.props.location.state.accessToken, this.props.location.state.locale)}
                        // component={Link} 
                        // to={{ pathname: '/dashboard/accesotemporal/', state: this.props.location.state !== undefined ? this.props.location.state : "" }}
                        // to="/dashboard/mapa"
                        // onClick={onItemClick('Page 3')}
                        >

                            <ListItemIcon><NotificationImportantOutlinedIcon /></ListItemIcon>
                            <ListItemText >{t("menuAfterLogin.viewAlertsWarnings")}</ListItemText>
                        </ListItem>


                        <ListItem button onClick={this.redirectToLogin.bind(this)}
                        // to="/dashboard/mapa"
                        // onClick={onItemClick('Page 3')}
                        >

                            <ListItemIcon><PowerSettingsNewOutlinedIcon /></ListItemIcon>
                            <ListItemText >{t("menuAfterLogin.logout")}</ListItemText>
                        </ListItem>
                    </List>
                    <Divider />
                </div>

                :
                <div
                    className={clsx(this.props.classes.list, {
                        [this.props.classes.fullList]: anchor === 'right' || anchor === 'left',
                    })}
                    role="presentation"
                // onClick={toggleDrawer(anchor, false)}
                // onKeyDown={toggleDrawer(anchor, false)}
                >
                    <div style={{ width: "200px", margin: "auto", padding: "15px" }}>
                        <img style={{ width: "150px" }} src={logo4gflota} alt="logo" />
                    </div>
                    <List>
                        <ListItem onClick={this.onCloseDrawer.bind(this)} button component={Link} to={{ pathname: '/dashboard/mapa', state: this.props.location.state !== undefined ? this.props.location.state : "" }}
                        // to="/dashboard/mapa"
                        // onClick={onItemClick('Page 3')}
                        >

                            <ListItemIcon><MapOutlinedIcon /></ListItemIcon>
                            <ListItemText >Mapa</ListItemText>
                        </ListItem>
                        <ListItem button onClick={this.goToAlertsAndWarnings.bind(this, this.props.location.state.accessToken, this.props.location.state.locale)}
                        // component={Link} 
                        // to={{ pathname: '/dashboard/accesotemporal/', state: this.props.location.state !== undefined ? this.props.location.state : "" }}
                        // to="/dashboard/mapa"
                        // onClick={onItemClick('Page 3')}
                        >

                            <ListItemIcon><NotificationImportantOutlinedIcon /></ListItemIcon>
                            <ListItemText >{t("menuAfterLogin.viewAlertsWarnings")}</ListItemText>
                        </ListItem>

                        <ListItem button onClick={this.redirectToLogin.bind(this)}
                        // to="/dashboard/mapa"
                        // onClick={onItemClick('Page 3')}
                        >

                            <ListItemIcon><PowerSettingsNewOutlinedIcon /></ListItemIcon>
                            <ListItemText >Cerrar sesión</ListItemText>
                        </ListItem>
                    </List>
                    <Divider />
                </div>

        )
    };

    render() {
        const { classes } = this.props;
        return (
            this.props.isAuthenticated === false ?
                <div>{this.redirectToLogin()}</div> :

                <div style={{ height: "calc(100% - 65px)" }}>



                    {/* <CssBaseline />
                <AppBar
                    position="fixed"
                    className={clsx(classes.appBar, {
                        [classes.appBarShift]: this.state.open,
                    })}
                >
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={this.handleDrawerOpen.bind(this)}
                            edge="start"
                            className={clsx(classes.menuButton, this.state.open && classes.hide)}
                        >
                            <MenuIcon />
                        </IconButton>
                        <img src={Logo} style={{ height: "45px" }} alt="4GFlota" className="img-responsive" />
                    </Toolbar>
                </AppBar>
                 */}
                    <AppBar position="static">
                        <Toolbar>
                            <IconButton onClick={this.handleDrawerOpen.bind(this)} edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                                <MenuIcon />
                            </IconButton>
                            <img src={Logo} style={{ height: "45px" }} alt="4GFlota" className="img-responsive" />
                        </Toolbar>
                    </AppBar>
                    {this.props.isAuthenticated !== false ?
                        <Container>
                            <Drawer
                                // className={classes.drawer}
                                // variant="persistent"
                                onClose={this.handleDrawerOpen.bind(this)}
                                anchor="left"
                                open={this.state.open}
                            // classes={{
                            //     paper: classes.drawerPaper,
                            // }}
                            >

                                {this.state.company === "instalaciones" ?
                                    this.listInstaladores(this.state.open) :
                                    this.list(this.state.open)}



                            </Drawer>

                        </Container>

                        : null
                    }

                    {/* <main
                    className={clsx(classes.content, {
                        [classes.contentShift]: this.state.open,
                    })}
                >
                    <div className={classes.drawerHeader} />
                    <div className={classes.toolbar} />
                    <Route exact path="/" />

                    {/* <Route path="/dashboard/menu">
                        <Menu />
                    </Route> */}

                    {/* </main> */}

                    {this.state.company === "instalaciones" ?
                        <div style={{ height: "100%", overflowY: "scroll", overflowX: "hidden" }}>
                            <Route isExact={true} path="/dashboard/instaladores/" render={(props) => {
                                // 
                                return <InstaladoresCheckControl selectedOt={this.props.location.selectedOt === false ? this.props.selectedOt : this.props.location.selectedOt} testOnclickList={this.testOnclickList} {...props} location={this.props.location} />;
                            }} />
                            <Route isExact={true} path="/dashboard/añadir" render={(props) => {
                                return <InstaladoresCheckControl testOnclickList={this.testOnclickList} {...props} location={this.props.location} />;
                            }} />
                            <Route isExact={true} path="/dashboard/historico" render={(props) => {
                                return <InstaladoresCheckControl testOnclickList={this.testOnclickList} {...props} location={this.props.location} />;
                            }} />
                            <Route isExact={true} path="/dashboard/configuracion-dispositivos/" render={(props) => {
                                return <InstaladoresCheckControl {...props} testOnclickList={this.testOnclickList} location={this.props.location} />;
                            }} />
                            <Route isExact={true} path="/dashboard/esquemas-canbus/" render={(props) => {
                                return <InstaladoresCheckControl {...props} testOnclickList={this.testOnclickList} location={this.props.location} />;
                            }} />
                            <Route isExact={true} path="/dashboard/gestion-manuales/" render={(props) => {
                                return <InstaladoresCheckControl {...props} testOnclickList={this.testOnclickList} location={this.props.location} />;
                            }} />
                            <Route isExact={true} path="/dashboard/ordenes-trabajo/" render={(props) => {
                                return <TaskOrdersRoutes  {...props} testOnclickList={this.testOnclickList} location={this.props.location} />
                                // <InstaladoresCheckControl companyName={this.state.company} AccessToken={this.props.location.state !== undefined ? this.props.location.state.userDataInfo.accessToken : ""} {...props} location={this.props.location.state !== undefined ? this.props.location : ""} />;
                            }} />

                        </div>
                        :
                        <div style={{ height: "100%", overflowY: "scroll", overflowX: "hidden", width: "100%" }}>
                            <Route isExact={true} path="/dashboard/mapa" render={(props) => {
                                return <Mapa {...props} location={this.props.location} origin={false} />;
                            }} />
                            <Route path="/dashboard/planning" render={(props) => {
                                return <DataTablePlaning companyName={this.state.company} AccessToken={this.props.location.state !== undefined ? this.props.location.state.userDataInfo.accessToken : ""} {...props} location={this.props.location.state !== undefined ? this.props.location : ""} />;
                            }} />
                            <Route path="/dashboard/taskOrderList" render={(props) => {
                                return <TaskOrderList companyName={this.state.company} AccessToken={this.props.location.state !== undefined ? this.props.location.state.userDataInfo.accessToken : ""} {...props} location={this.props.location.state !== undefined ? this.props.location : ""} />;
                            }} />
                            <Route isExact={true} path="/dashboard/accesotemporal/" render={(props) => {
                                return <TemporalAccessList isAuthenticated={this.props.isAuthenticated} companyName={this.state.company} AccessToken={this.props.location.state.AccessToken} {...props} history={this.props.history !== undefined ? this.props.history : ""} location={this.props.location.state !== undefined ? this.props.location : ""} />;
                            }} />
                             <Route isExact={true} path="/dashboard/sondas-bt/" render={(props) => {
                                return <SondasBT isAuthenticated={this.props.isAuthenticated} companyName={this.state.company} AccessToken={this.props.location.state.AccessToken} {...props} history={this.props.history !== undefined ? this.props.history : ""} location={this.props.location.state !== undefined ? this.props.location : ""} />;
                            }} />
                            <Route isExact={true} path="/dashboard/buzon-sugerencias/" render={(props) => {
                                return <BuzonSugerencias isAuthenticated={this.props.isAuthenticated} companyName={this.state.company} AccessToken={this.props.location.state.AccessToken} {...props} history={this.props.history !== undefined ? this.props.history : ""} location={this.props.location.state !== undefined ? this.props.location : ""} />;
                            }} />
                            <Route isExact={true} path="/dashboard/pois-recurrentes/" render={(props) => {
                                return <RecurringPois isAuthenticated={this.props.isAuthenticated} companyName={this.state.company} AccessToken={this.props.location.state.AccessToken} {...props} history={this.props.history !== undefined ? this.props.history : ""} location={this.props.location.state !== undefined ? this.props.location : ""} />;
                            }} />
                            <Route isExact={true} path="/dashboard/gestion-grupos-acceso-temporal/" render={(props) => {
                                return <ControlTemporalAccessGroup isAuthenticated={this.props.isAuthenticated} companyName={this.state.company} AccessToken={this.props.location.state.AccessToken} {...props} history={this.props.history !== undefined ? this.props.history : ""} location={this.props.location.state !== undefined ? this.props.location : ""} />;
                            }} />
                            <Route path="/dashboard/incident-management" render={(props) => {
                                return <DataTableIncidentManagement companyName={this.state.company} AccessToken={this.props.location.state !== undefined ? this.props.location.state.userDataInfo.accessToken : ""} {...props} location={this.props.location.state !== undefined ? this.props.location : ""} />;
                            }} />
                            <Route path="/dashboard/alertas-avisos" render={(props) => {
                                return <AlertsWarnings companyName={this.state.company} AccessToken={this.props.location.state !== undefined ? this.props.location.state.userDataInfo.accessToken : ""} {...props} location={this.props.location.state !== undefined ? this.props.location : ""} />;
                            }} />



                        </div>

                    }
                    {/* <Route path="/dashboard/planning/cambioMasivo" render={(props) => {
                    return <DataTablePlaning companyName={this.state.company} AccessToken={this.props.location.state !== undefined ? this.props.location.state.userDataInfo.accessToken : ""} {...props} location={this.props.location.state !== undefined ? this.props.location : ""} />;
                }} /> */}
                </div>


        )
    }
}
export default withRouter(compose(
    withTranslation(),
    withStyles(styles, { withTheme: true }),


)(DrawerComponent))
