
import React from "react";

import { API } from "aws-amplify";
import { withRouter } from 'react-router-dom';
import config from '../../../config';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Checkbox from '@material-ui/core/Checkbox';
import BeatLoader from 'react-spinners/BeatLoader';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
// import { Auth } from "aws-amplify";
import { setSessionCookie } from '../../global/cookies/cookies';
import { withStyles } from '@material-ui/core/styles';
// import './SignInResponsive.css';
// import './SignIn.css';


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const styles = theme => ({

  rootCheckbox: {
    fontSize: "10rem"
  }
});

const spinnerCss = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  marginLeft: '-30px'
}
class SignIn extends React.Component {

  constructor(props) {

    super(props);
    this.state = {
      company: "",
      user: "",
      password: "",
      isOpenAlertError: false,
      textAlertError: "",
      isChecked: localStorage.getItem('user') !== null && localStorage.getItem('password') !== null ? true : false,
      isLoading: false,
      locale: "es",
      firebaseToken: "",
      dateNotification: ""
    };
  }
  handleChange = option => (event) => {
    this.setState({
      [option]: event.target.value
    });
  }

  validationChangePassword = () => {
    if (this.state.company === "") {
      this.setState({
        isOpenAlertError: !this.state.isOpenAlertError,
        textAlertError: "Debe introducir una compañia para poder cambiar la contraseña"
      });
    } else {
      window.location.replace("https://" + this.state.company + ".4gflota.com?recoverpass=true");
    }
  }

  isEmpty(obj) {
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        return false;
      }
    }

    return JSON.stringify(obj) === JSON.stringify({});
  }

  validateForm() {
    return (this.state.company.length > 0 && this.state.user.length > 0 && this.state.password.length > 0);
  }
  handleSubmit = async (e, notification) => {
    e.preventDefault();
  
    let json = {};
    let token = "";
    let haveToken = false;
  
    const currentPath = this.props.location.pathname;
    if (currentPath.includes("token")) {
      let currentPathSplit = currentPath.split("/");
      token = currentPathSplit[2];
      haveToken = true;
      localStorage.setItem("FCMToken", token);
    }
    if (haveToken && this.state.isChecked) {
      json = {
        "companyName": this.state.company,
        "userName": this.state.user,
        "password": this.state.password,
        "deviceToken": token,
        "mobile": true
      }
    } else if (haveToken && !this.state.isChecked) {
      json = {
        "companyName": this.state.company,
        "userName": this.state.user,
        "password": this.state.password,
        "mobile": true
      }
    } else {
      json = {
        "companyName": this.state.company,
        "userName": this.state.user,
        "password": this.state.password,
        "mobile": false
      }
    }
  
    let apiName = '4GFlota';
    let path = 'userLogin';
    let myInit = {
      header: {},
      body: json,
      response: true,
    }
  
    this.props.getCompanyName(this.state.company);
    this.setState({
      isLoading: true
    }, async () => {
      try {
        // Control de timeout
        const timeout = new Promise((_, reject) => setTimeout(() => reject(new Error('timeout')), 10000));
        const response = await Promise.race([API.post(apiName, path, myInit), timeout]);
  
        if (response.data.userId === 0) {
          this.setState({
            isOpenAlertError: !this.state.isOpenAlertError,
            textAlertError: "El usuario, compañia o contraseña es incorrecto",
            isLoading: false
          });
        } else {
          localStorage.setItem('userDataInfo', JSON.stringify(response.data));
          setSessionCookie(this.state.user);
  
          if (this.state.isChecked) {
            localStorage.setItem('company', this.state.company);
            localStorage.setItem('user', this.state.user);
            localStorage.setItem('password', this.state.password);
          } else {
            localStorage.setItem('company', this.state.company);
            localStorage.setItem('user', "");
            localStorage.setItem('password', "");
          }
  
          this.props.userHasAuthenticated(true);
  
          const currentPath = this.props.location.pathname;
          let fromNotification = false;
          if (currentPath.includes("token") && currentPath.includes("notification")) {
            fromNotification = true;
          }
  
          this.props.history.push({
            pathname: '/dashboard/' + this.state.company,
            state: {
              userDataInfo: response.data,
              ApiURL: config.apiGateway.URL,
              locale: this.state.locale,
              notification: fromNotification,
              dateNotification: currentPath.split("/")[3]
            },
            accessToken: response.data.accessToken
          });
        }
      } catch (error) {
        console.log(error);
        if (error.message === 'timeout') {
          //alert("La solicitud ha tomado demasiado tiempo, por favor intente de nuevo.");
          this.setState({
            isOpenAlertError: !this.state.isOpenAlertError,
            textAlertError: "El usuario, compañia o contraseña es incorrecto",
            isLoading: false
          });
        } else if (error.response && error.response.status === 400) {
          //alert("Error en los datos enviados");
          this.setState({
            isOpenAlertError: !this.state.isOpenAlertError,
            textAlertError: "El usuario, compañia o contraseña es incorrecto",
            isLoading: false
          });
        } else {
          alert("Ha ocurrido un error inesperado.");
        }
        this.setState({ isLoading: false });
      }
    });
  }
  

  handleClose = () => {
    // const handleClose = (event, reason) => {

    // };
    // if (reason === 'clickaway') {
    //   return;
    // }

    this.setState({
      isOpenAlertError: !this.state.isOpenAlertError
    })
  }
  componentDidMount() {

    const currentPath = this.props.location.pathname;
    //alert(currentPath);
    let haveToken = false;
    let fromNotification = false;
    let token = "";
    if (currentPath.includes("token") && currentPath.includes("notification")) {
      let currentPathSplit = currentPath.split("/");
      token = currentPathSplit[2];
      haveToken = true;
      fromNotification = true;
    } else if (currentPath.includes("token")) {
      let currentPathSplit = currentPath.split("/");
      token = currentPathSplit[2];
      haveToken = true;
      //console.log("ok");
    }

    document.getElementById("pin").style.display = "none";
    let company = localStorage.getItem('company');
    let user = localStorage.getItem('user');
    let password = localStorage.getItem('password');
    if (this.state.isChecked) {
      if (localStorage.getItem('user') !== "") {
        this.setState({
          company: company,
          user: user,
          password: password,
          firebaseToken: haveToken ? token : ""

        }, () => {
          if (this.props.location.state === undefined) {
            if (haveToken) {
              document.getElementById("buttonSubmit").click();
            } else if (fromNotification) {
              document.getElementById("buttonSubmit").click(fromNotification);
            }
          }
        });
      } else {
        this.setState({
          company: company,
          isChecked: false,
          //firebaseToken: haveToken ? token : ""
        });
      }
    }

    try {
      if (localStorage.getItem("locale") !== null) {
        let locale = localStorage.getItem('locale');
        this.setState({
          locale: locale
        }, () => {
          this.props.i18n.changeLanguage(locale);
        });
      } else {
        localStorage.setItem('locale', "es");
        this.setState({
          locale: "es"
        }, () => {
          this.props.i18n.changeLanguage("es");
        });
      }
    } catch (error) {
      this.setState({
        locale: "es"
      }, () => {
        this.props.i18n.changeLanguage("es");
      });
    }

    // else {
    //   this.setState({
    //     company: localStorage.getItem('company'),
    //     user: localStorage.getItem('user'),
    //     password: localStorage.getItem('password')
    //   });
    // }
  }

  IsCheckedHandle = () => {
    this.setState({
      isChecked: !this.state.isChecked
    });
  }

  handleOnClickChangeLanguage(locale) {
    // console.log(locale);
    localStorage.setItem('locale', locale);
    this.setState({ isLoading: true, locale: locale }, () => {
      this.props.i18n.changeLanguage(locale);

      setTimeout(() => {
        this.setState({ isLoading: false })
      }, 1000);
    })
  }

  render() {
    const { t, classes } = this.props;
    return (
      <div className="wrapper" style={{ width: "100%", height: "100%", background: "#d9dadb" }}>
        {this.state.isLoading ?
          <div>
            <div style={{ zIndex: "1000" }} className="loadingSpinnerEdit">
              <BeatLoader css={spinnerCss} size={15} margin={4} color={"#bf2349"} />
            </div>
            <div>
              <div className="alertContainer">
                <Snackbar
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  open={this.state.isOpenAlertError} autoHideDuration={6000} onClose={this.handleClose}>
                  <Alert style={{ fontSize: "14px", width: "100%", background: "#bf2349", }} severity="error"  onClose={this.handleClose}>
                    {this.state.textAlertError}
                  </Alert>
                </Snackbar>
              </div>

              <div className="container main-form-container">
                <div className="row">
                  <div className="col-md-12">
                    <div className="logo-container"><img style={{ margin: "auto" }} src="https://staticcontent.inelcan.com/img/4gflota_logo_new.png" alt="4GFlota" className="img-responsive" /> </div>

                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <form autoComplete="false">
                      <ul className="form-container">

                        {/* <li><p style={{background}}>El usuario, compañia o contraseña es incorrecto</p></li> */}
                        <li><input type="text" name="" placeholder={t("loginForm.companyName")} value={this.state.company} onChange={this.handleChange('company')} /></li>
                        <li><input type="text" name="" placeholder={t("loginForm.userName")} value={this.state.user} onChange={this.handleChange('user')} /></li>
                        <li><input type="password" name="" autoComplete="off" placeholder={t("loginForm.password")} value={this.state.password} onChange={this.handleChange('password')} /></li>
                        <li> <button ref={this.button} className="btn btn-default" onClick={this.handleSubmit.bind(this)} disabled={!this.validateForm()}>{t("loginForm.logIn")}</button></li>
                        <li>
                          <div className="loginLabel" style={{ margin: "auto", color: "#505050", textAlign: "center" }}>
                            <Checkbox className={classes.rootCheckbox} checked={this.state.isChecked} onChange={this.IsCheckedHandle}></Checkbox>
                            <label color="pink">{t("loginForm.remember")}</label>
                          </div>

                        </li>
                      </ul>
                    </form>
                  </div>
                </div>

                <div className="row"
                  style={{ color: "#858585", marginTop: "10%", textAlign: "center" }}>
                  <p style={{ paddingBottom: "10px" }}><button className="button-recoverPassword" onClick={this.validationChangePassword} >{t("loginForm.lostPassword")}</button></p>

                  <p style={{ alignContent: "center" }}><a className="aClass" href="http://www.4gflota.com" target="_blank" rel="noopener noreferrer">www.4gflota.com</a></p>

                </div>
              </div>

            </div>
          </div>

          :
          <div>
            <nav className="navbar navbar-default">
              <div className="container-fluid">
                <div className="navbar-header">
                  <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-controls="navbar">
                    <span className="sr-only">Toggle navigation</span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                  </button>
                </div>
                <div id="navbar" className="navbar-collapse collapse" aria-expanded="false" style={{ height: "0px" }}>
                  <span style={{ display: "block !important" }} className="nav-title">{t('loginNavLanguages.titleLanguage')}</span>
                  <ul className="nav navbar-nav navbar-right">
                    <li><p onClick={this.handleOnClickChangeLanguage.bind(this, "es")}>{t('loginNavLanguages.spanish')}</p></li>
                    <li><p onClick={this.handleOnClickChangeLanguage.bind(this, "en")}>{t('loginNavLanguages.english')}</p></li>
                    <li><p onClick={this.handleOnClickChangeLanguage.bind(this, "fr")}>{t('loginNavLanguages.french')}</p></li>
                  </ul>
                </div>
              </div>
            </nav>
            <div className="alertContainer">
              <Snackbar style={{ margin: "auto", position: "relative", top: "10px" }} open={this.state.isOpenAlertError} autoHideDuration={6000} onClose={this.handleClose}>
                <Alert style={{ fontSize: "14px", width: "100%", background: "#bf2349", }} severity="error" iconMapping="error" onClose={this.handleClose}>
                  {this.state.textAlertError}
                </Alert>
              </Snackbar>
            </div>
            <div className="container main-form-container">
              <div className="row">
                <div className="col-md-12">
                  <div className="logo-container"><img style={{ margin: "auto" }} src="https://staticcontent.inelcan.com/img/4gflota_logo_new.png" alt="4GFlota" className="img-responsive" /> </div>

                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <form autoComplete="false">
                    <ul className="form-container">

                      {/* <li><p style={{background}}>El usuario, compañia o contraseña es incorrecto</p></li> */}
                      <li><input type="text" name="" placeholder={t("loginForm.companyName")} value={this.state.company} onChange={this.handleChange('company')} /></li>
                      <li><input type="text" name="" placeholder={t("loginForm.userName")} value={this.state.user} onChange={this.handleChange('user')} /></li>
                      <li><input type="password" name="" autoComplete="off" placeholder={t("loginForm.password")} value={this.state.password} onChange={this.handleChange('password')} /></li>
                      <li> <button id="buttonSubmit" className="btn btn-default" onClick={this.handleSubmit.bind(this)} disabled={!this.validateForm()}>{t("loginForm.logIn")}</button></li>
                      <li>
                        <div style={{ margin: "auto", color: "#505050", textAlign: "center" }}>
                          <Checkbox checked={this.state.isChecked} onChange={this.IsCheckedHandle}></Checkbox>
                          <label color="pink">{t("loginForm.remember")}</label>
                        </div>

                      </li>
                    </ul>
                  </form>
                </div>
              </div>

              <div className="row"
                style={{ color: "#858585", marginTop: "10%", textAlign: "center" }}>
                <p style={{ paddingBottom: "10px" }}><button className="button-recoverPassword" onClick={this.validationChangePassword} >{t("loginForm.lostPassword")}</button></p>

                <p style={{ alignContent: "center" }}><a className="aClass" href="http://www.4gflota.com" target="_blank" rel="noopener noreferrer">www.4gflota.com</a></p>

              </div>
            </div>
          </div>

        }

      </div>
    );
  };
}

// export default withRouter(withStyles(styles, { withTheme: true })(InstaladoresCheckControl));
// export default withTranslation(withRouter(withStyles(styles, { withTheme: true })))(SignIn)

// export default withRouter(withStyles(styles, { withTheme: true },withTranslation()))(SignIn)
// export default withStyles(styles, { withTheme: true },withRouter((SignIn)))
// export default withRouter(withTranslation()(SignIn));
// export default compose(
//   // withStyles(styles, { withTheme: true }),
//   withRouter(),
//   withTranslation(),
// )(SignIn);

export default withRouter(compose(
  withTranslation(),
  withStyles(styles, { withTheme: true }),


)(SignIn))