// export const dataTranslateSanFernando = {
//     HoraSalidaReal: "SALIDA LURIN",
//     Alimento: "OT",
//     Placa: "TRACTO",
//     Semirremolque: "TOLVA",
//     Planta: "PLANTEL",
//     EtaPlanta: "ETA GRANJA",
//     HoraLlegadaGranja: "LLEGADA GRANJA",
//     HoraSalidaGranja: "SALIDA GRANJA",
//     HoraRetornoPlanta: "LLEGADA PLANTA",
//     Estado: "ESTADO",
//     Eta: "ETA PLANTA",
// }
export const SanfernandoStartHeaders = [
    { id: "1", content: 'Estado', label: 'estado' },
    { id: "2", content: 'Pto.Planificación', label: 'orgVentas' },
    { id: "3", content: 'Orden Servicio', label: 'descripcion' },
    { id: "4", content: 'Tracto', label: 'cabeza' },
    { id: "5", content: 'Tolva', label: 'remolque' },
    { id: "6", content: 'Conductor', label: 'conductor' },
    { id: "7", content: 'Tfno Conductor', label: 'tfnoConductor' },
    { id: "8", content: 'Destino', label: 'destino' },
    { id: "9", content: 'Id Region Destino', label: 'idRegionDestino' },
    { id: "10", content: 'Productos   ', label: 'productos' },
    { id: "11", content: 'Id Region Origen', label: 'idRegionCargadero' },
    { id: "12", content: 'Salida Origen', label: 'salidaCargadero' },
    { id: "13", content: 'Retorno Origen', label: 'llegadaCargadero' },
    { id: "14", content: 'Ventana Min SF', label: 'etaEstimadaLlegada' },
    { id: "15", content: 'Ventana Max SF', label: 'etaEstimadaSalida' },
    { id: "16", content: 'Eta Llegada Destino', label: 'etaLlegadaCliente' },
    { id: "17", content: 'Llegada Destino', label: 'llegadaCliente' },
    { id: "18", content: 'Salida Destino', label: 'salidaCliente' },
    { id: "19", content: 'ETA Retorno SF', label: 'etaEstimadaLlegadaCargadero' },
    { id: "20", content: 'ETA Retorno Origen', label: 'etaLlegadaCargadero' },
    { id: "21", content: 'Kms Estimados', label: 'kmsEstimados' },
    { id: "22", content: 'Kms Recorridos', label: 'kmsRecorridos' },
    { id: "23", content: 'Velocidad Media', label: 'velocidadMedia' },
    { id: "24", content: 'Orden', label: 'orden' },
    { id: "25", content: 'Fecha Activación', label: 'fechaActivacion' },
    { id: "26", content: 'Fecha Inicio Programada', label: 'fechaServicio' },
    { id: "27", content: 'Tiempo OT', label: 'diffTimeOt' },
    { id: "28", content: 'Id Region Fin Cargadero', label: 'idRegionFinCargadero' },
    { id: "29", content: 'Tempertura', label: 'temperatura' },



]

export const DisaStartHeaders = [
    { id: "1", content: 'Estado', label: 'estado' },
    { id: "2", content: 'Org Ventas', label: 'orgVentas' },
    { id: "3", content: 'NºPedido', label: 'descripcion' },
    { id: "4", content: 'Cabeza', label: 'cabeza' },
    { id: "5", content: 'Remolque', label: 'remolque' },
    { id: "6", content: 'Conductor', label: 'conductor' },
    { id: "7", content: 'Tfno Conductor', label: 'tfnoConductor' },
    { id: "8", content: 'Destino', label: 'destino' },
    { id: "9", content: 'Id Region Destino', label: 'idRegionDestino' },
    { id: "10", content: 'Productos', label: 'productos' },
    { id: "11", content: 'Id Region Cargadero', label: 'idRegionCargadero' },
    { id: "12", content: 'Salida Cargadero', label: 'salidaCargadero' },
    { id: "13", content: 'Finalizado', label: 'llegadaCargadero' },
    { id: "14", content: 'Llegada Estimada', label: 'etaEstimadaLlegada' },
    { id: "15", content: 'Salida Estimada', label: 'etaEstimadaSalida' },
    { id: "16", content: 'Eta Llegada Cliente', label: 'etaLlegadaCliente' },
    { id: "17", content: 'Llegada Cliente', label: 'llegadaCliente' },
    { id: "18", content: 'Salida Cliente', label: 'salidaCliente' },
    { id: "19", content: 'Eta Estimada Llegada Cargadero', label: 'etaEstimadaLlegadaCargadero' },
    { id: "20", content: 'Eta Llegada Cargadero', label: 'etaLlegadaCargadero' },
    { id: "21", content: 'Kms Estimados', label: 'kmsEstimados' },
    { id: "22", content: 'Kms Recorridos', label: 'kmsRecorridos' },
    { id: "23", content: 'Velocidad Media', label: 'velocidadMedia' },
    { id: "24", content: 'Orden', label: 'orden' },
    { id: "25", content: 'Tiempo OT', label: 'diffTimeOt' },
    { id: "26", content: 'Temperatura', label: 'temperatura' },
]

export const DisaPlaningStartHeaders = [
    { id: "1", content: 'Nº Pedido', label: 'NumPedido' },
    { id: "2", content: 'Cliente', label: 'Cliente' },
    { id: "3", content: 'Turno', label: 'Turno' },
    { id: "4", content: 'Cod Conductor', label: 'CodConductor' },
    { id: "5", content: 'Conductor', label: 'Conductor' },
    { id: "6", content: 'Tractora', label: 'CabezaTractora' },
    { id: "7", content: 'Remolque', label: 'Semirremolque' },
    { id: "8", content: 'Salida Instalacion', label: 'HoraSalidaInstalacion' },
    { id: "9", content: 'Llegada Cliente', label: 'HoraLlegadaCliente' },
    { id: "10", content: 'Salida Cliente', label: 'HoraSalidaCliente' },
    { id: "11", content: 'Llegada Instalacion', label: 'HoraLlegadaInstalacion' },
    { id: "12", content: 'Cod Centro', label: 'CodCentro' },
    { id: "13", content: 'Org Ventas', label: 'OrgVentas' },
    { id: "14", content: 'Canal', label: 'Canal' },
    { id: "15", content: 'Estado', label: 'Estado' }
]

export const dataTranslateSanfernando = {
    Estado: "Estado",
    OrgVentas: "Pto.Planificación",
    Descripcion: "Orden Servicio",
    Cabeza: "Tracto",
    Remolque: "Tolva",
    Conductor: "Conductor",
    TfnoConductor: "Tfno Conductor",
    Destino: "Destino",
    IdRegionDestino: "Id Region Destino",
    Productos: "Productos",
    IdRegionCargadero: "Id Region Origen",
    SalidaCargadero: "Salida Origen",
    LlegadaCargadero: "Fin de Viaje",
    EtaEstimadaLlegada: "Ventana Min SF",
    EtaEstimadaSalida: "Ventana Max SF",
    EtaLlegadaCliente: "Eta Llegada Destino",
    LlegadaCliente: "Llegada Destino",
    SalidaCliente: "Salida Destino",
    EtaEstimadaLlegadaCargadero: "ETA Retorno SF",
    EtaLlegadaCargadero: "ETA Retorno Origen",
    KmsEstimados: "Kms Estimados",
    KmsRecorridos: "Kms Recorridos",
    VelocidadMedia: "Velocidad Media",
    Orden: "orden",
    FechaActivacion: "Fecha Activación",
    FechaServicio: "Fecha Inicio Programada",
    DiffTimeOt: "Tiempo OT",
    IdRegionFinCargadero: "Id Region Fin Cargadero",
    temperatura: "Temperatura",

}

export const listDataTranslateSanfernando = {
    headers: {
        '1': { id: "1", content: 'Estado', label: 'estado' },
        '2': { id: "2", content: 'Pto.Planificación', label: 'orgVentas' },
        '3': { id: "3", content: 'Orden Servicio', label: 'descripcion' },
        '4': { id: "4", content: 'Tracto', label: 'cabeza' },
        '5': { id: "5", content: 'Tolva', label: 'remolque' },
        '6': { id: "6", content: 'Conductor', label: 'conductor' },
        '7': { id: "7", content: 'Tfno Conductor', label: 'tfnoConductor' },
        '8': { id: "8", content: 'Destino', label: 'destino' },
        '9': { id: "9", content: 'Id Region Destino', label: 'idRegionDestino' },
        '10': { id: "10", content: 'Productos', label: 'productos' },
        '11': { id: "11", content: 'Id Region Origen', label: 'idRegionCargadero' },
        '12': { id: "12", content: 'Salida Origen', label: 'salidaCargadero' },
        '13': { id: "13", content: 'Fin de Viaje', label: 'llegadaCargadero' },
        '14': { id: "14", content: 'Ventana Min SF', label: 'etaEstimadaLlegada' },
        '15': { id: "15", content: 'Ventana Max SF', label: 'etaEstimadaSalida' },
        '16': { id: "16", content: 'ETA Llegada Destino', label: 'etaLlegadaCliente' },
        '17': { id: "17", content: 'Llegada Destino', label: 'llegadaCliente' },
        '18': { id: "18", content: 'Salida Destino', label: 'salidaCliente' },
        '19': { id: "19", content: 'ETA Retorno SF', label: 'etaEstimadaLlegadaCargadero' },
        '20': { id: "20", content: 'ETA Retorno Origen', label: 'etaLlegadaCargadero' },
        '21': { id: "21", content: 'Kms Estimados', label: 'kmsEstimados' },
        '22': { id: "22", content: 'Kms Recorridos', label: 'kmsRecorridos' },
        '23': { id: "23", content: 'Velocidad Media', label: 'velocidadMedia' },
        '24': { id: "24", content: 'Orden', label: 'orden' },
        '25': { id: "25", content: 'Fecha Activación', label: 'fechaActivacion' },
        '26': { id: "26", content: 'Fecha Inicio Programada', label: 'fechaServicio' },
        '27': { id: "27", content: 'Tiempo OT', label: 'diffTimeOt' },
        '28': { id: "28", content: 'Id Region Fin Cargadero', label: 'idRegionFinCargadero' },
        '29': { id: "29", content: 'Temperatura', label: 'temperatura' },

    },
    columns: {
        'column-1': {
            id: 'column-1',
            title: 'Opciones',
            headersIds: []
        },
        'column-2': {
            id: 'column-2',
            title: 'Opciones seleccionadas',
            headersIds: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29"]
            // headersIds: []
        }
    },
    //facilitate reordering of the columns
    columnOrder: ['column-1', 'column-2'],
}

export const dataTranslateDisa = {
    Estado: "Estado",
    OrgVentas: "Org Ventas",
    Descripcion: "Descripción",
    Cabeza: "Cabeza",
    Remolque: "Remolque",
    Conductor: "Conductor",
    TfnoConductor: "Tfno Conductor",
    Destino: "Destino",
    IdRegionDestino: "Id Region Destino",
    Productos: "Productos",
    IdRegionCargadero: "Id Region Cargadero",
    SalidaCargadero: "Salida Cargadero",
    LlegadaCargadero: "Llegada Cargadero",
    EtaEstimadaLlegada: "Llegada Estimada",
    EtaEstimadaSalida: "Salida Estimada",
    EtaLlegadaCliente: "Eta Llegada Cliente",
    LlegadaCliente: "Llegada Cliente",
    SalidaCliente: "Salida Cliente",
    EtaEstimadaLlegadaCargadero: "Eta Estimada Llegada Cargadero",
    EtaLlegadaCargadero: "Eta Llegada Cargadero",
    KmsEstimados: "Kms Estimados",
    KmsRecorridos: "Kms Recorridos",
    VelocidadMedia: "Velocidad Media",
    Orden: "orden",
    DiffTimeOt: "Tiempo OT",
    temperatura: "Temperatura",
}

export const listDataTranslateDisa = {

    headers: {
        '1': { id: "1", content: 'Estado', label: 'estado' },
        '2': { id: "2", content: 'Org Ventas', label: 'orgVentas' },
        '3': { id: "3", content: 'Nº Pedido', label: 'descripcion' },
        '4': { id: "4", content: 'Cabeza', label: 'cabeza' },
        '5': { id: "5", content: 'Remolque', label: 'remolque' },
        '6': { id: "6", content: 'Conductor', label: 'conductor' },
        '7': { id: "7", content: 'Tfno Conductor', label: 'tfnoConductor' },
        '8': { id: "8", content: 'Destino', label: 'destino' },
        '9': { id: "9", content: 'Id Region Destino', label: 'idRegionDestino' },
        '10': { id: "10", content: 'Productos', label: 'productos' },
        '11': { id: "11", content: 'Id Region Cargadero', label: 'idRegionCargadero' },
        '12': { id: "12", content: 'Salida Cargadero', label: 'salidaCargadero' },
        '13': { id: "13", content: 'Finalizado', label: 'llegadaCargadero' },
        '14': { id: "14", content: 'Llegada Estimada', label: 'etaEstimadaLlegada' },
        '15': { id: "15", content: 'Salida Estimada', label: 'etaEstimadaSalida' },
        '16': { id: "16", content: 'Eta Llegada Cliente', label: 'etaLlegadaCliente' },
        '17': { id: "17", content: 'Llegada Cliente', label: 'llegadaCliente' },
        '18': { id: "18", content: 'Salida Cliente', label: 'salidaCliente' },
        '19': { id: "19", content: 'Eta Estimada Llegada Cargadero', label: 'etaEstimadaLlegadaCargadero' },
        '20': { id: "20", content: 'Eta Llegada Cargadero', label: 'etaLlegadaCargadero' },
        '21': { id: "21", content: 'Kms Estimados', label: 'kmsEstimados' },
        '22': { id: "22", content: 'Kms Recorridos', label: 'kmsRecorridos' },
        '23': { id: "23", content: 'Velocidad Media', label: 'velocidadMedia' },
        '24': { id: "24", content: 'Orden', label: 'orden' },
        '25': { id: "25", content: 'Tiempo OT', label: 'diffTimeOt' },
        '26': { id: "26", content: 'Temperatura', label: 'temperatura' },

    },
    columns: {
        'column-1': {
            id: 'column-1',
            title: 'Opciones',
            headersIds: []
        },
        'column-2': {
            id: 'column-2',
            title: 'Opciones seleccionadas',
            // headersIds: ["1","2","3","4","5","6","7","8","9","10","11","12","13","14","15","16","17","18","19","20","21","22","23","24"]
            headersIds: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26"]
        }
    },
    //facilitate reordering of the columns
    columnOrder: ['column-1', 'column-2'],
}

export const dataTranslateLogistics = {
    Estado: "Estado",
    OrgVentas: "Org Ventas",
    Descripcion: "Descripción",
    Cabeza: "Cabeza",
    Remolque: "Remolque",
    Conductor: "Conductor",
    TfnoConductor: "Tfno Conductor",
    Destino: "Región",
    IdRegionDestino: "Id Region Destino",
    Productos: "Productos",
    IdRegionCargadero: "Id Region Base",
    SalidaCargadero: "Salida Base",
    LlegadaCargadero: "Llegada Base",
    EtaEstimadaLlegada: "Llegada Estimada",
    EtaEstimadaSalida: "Salida Estimada",
    EtaLlegadaCliente: "Eta Llegada Cliente",
    LlegadaCliente: "Llegada Cliente",
    SalidaCliente: "Salida Cliente",
    EtaEstimadaLlegadaCargadero: "Eta Estimada Llegada Base",
    EtaLlegadaCargadero: "Eta Llegada Base",
    KmsEstimados: "Kms Estimados",
    KmsRecorridos: "Kms Recorridos",
    VelocidadMedia: "Velocidad Media",
    VelocidadMaxima: "Velocidad Máxima",
    EventosMobilEye: "Eventos MobilEye",
    Orden: "Orden",
    DiffTimeOt: "Tiempo OT",
    temperatura: "Temperatura",
}

export const listDataTranslateLogistics = {

    headers: {
        '1': { id: "1", content: 'Estado', label: 'estado' },
        '2': { id: "2", content: 'Org Ventas', label: 'orgVentas' },
        '3': { id: "3", content: 'Nº Pedido', label: 'descripcion' },
        '4': { id: "4", content: 'Cabeza', label: 'cabeza' },
        '5': { id: "5", content: 'Remolque', label: 'remolque' },
        '6': { id: "6", content: 'Conductor', label: 'conductor' },
        '7': { id: "7", content: 'Tfno Conductor', label: 'tfnoConductor' },
        '8': { id: "8", content: 'Región', label: 'destino' },
        '9': { id: "9", content: 'Id Region Destino', label: 'idRegionDestino' },
        '10': { id: "10", content: 'Productos', label: 'productos' },
        '11': { id: "11", content: 'Id Region Base', label: 'idRegionCargadero' },
        '12': { id: "12", content: 'Salida Base', label: 'salidaCargadero' },
        '13': { id: "13", content: 'Finalizado', label: 'llegadaCargadero' },
        '14': { id: "14", content: 'Llegada Estimada', label: 'etaEstimadaLlegada' },
        '15': { id: "15", content: 'Salida Estimada', label: 'etaEstimadaSalida' },
        '16': { id: "16", content: 'Eta Llegada Región', label: 'etaLlegadaCliente' },
        '17': { id: "17", content: 'Llegada Región', label: 'llegadaCliente' },
        '18': { id: "18", content: 'Salida Región', label: 'salidaCliente' },
        '19': { id: "19", content: 'Eta Estimada Llegada Base', label: 'etaEstimadaLlegadaCargadero' },
        '20': { id: "20", content: 'Eta Llegada Base', label: 'etaLlegadaCargadero' },
        '21': { id: "21", content: 'Kms Estimados', label: 'kmsEstimados' },
        '22': { id: "22", content: 'Kms Recorridos', label: 'kmsRecorridos' },
        '23': { id: "23", content: 'Velocidad Media', label: 'velocidadMedia' },
        '24': { id: "24", content: 'Velocidad Máxima', label: 'velocidadMaxima' },
        '25': { id: "25", content: 'Eventos mobileye', label: 'eventosMobilEye' },
        '26': { id: "26", content: 'Orden', label: 'orden' },
        '27': { id: "27", content: 'Tiempo OT', label: 'diffTimeOt' },
        '28': { id: "28", content: 'Temperatura', label: 'temperatura' },
    },
    columns: {
        'column-1': {
            id: 'column-1',
            title: 'Opciones',
            headersIds: []
        },
        'column-2': {
            id: 'column-2',
            title: 'Opciones seleccionadas',
            // headersIds: ["1","2","3","4","5","6","7","8","9","10","11","12","13","14","15","16","17","18","19","20","21","22","23","24"]
            headersIds: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28"]
        }
    },
    //facilitate reordering of the columns
    columnOrder: ['column-1', 'column-2'],
}

export const logisticsStartHeaders = [
    { id: "1", content: 'Estado', label: 'estado' },
    { id: "2", content: 'Org Ventas', label: 'orgVentas' },
    { id: "3", content: 'NºPedido', label: 'descripcion' },
    { id: "4", content: 'Cabeza', label: 'cabeza' },
    { id: "5", content: 'Remolque', label: 'remolque' },
    { id: "6", content: 'Conductor', label: 'conductor' },
    { id: "7", content: 'Tfno Conductor', label: 'tfnoConductor' },
    { id: "8", content: 'Región', label: 'destino' },
    { id: "9", content: 'Id Region Destino', label: 'idRegionDestino' },
    { id: "10", content: 'Productos', label: 'productos' },
    { id: "11", content: 'Id Region Base', label: 'idRegionCargadero' },
    { id: "12", content: 'Salida Base', label: 'salidaCargadero' },
    { id: "13", content: 'Finalizado', label: 'llegadaCargadero' },
    { id: "14", content: 'Llegada Estimada', label: 'etaEstimadaLlegada' },
    { id: "15", content: 'Salida Estimada', label: 'etaEstimadaSalida' },
    { id: "16", content: 'Eta Llegada Región', label: 'etaLlegadaCliente' },
    { id: "17", content: 'Llegada Región', label: 'llegadaCliente' },
    { id: "18", content: 'Salida Región', label: 'salidaCliente' },
    { id: "19", content: 'Eta Estimada Llegada Base', label: 'etaEstimadaLlegadaCargadero' },
    { id: "20", content: 'Eta Llegada Base', label: 'etaLlegadaCargadero' },
    { id: "21", content: 'Kms Estimados', label: 'kmsEstimados' },
    { id: "22", content: 'Kms Recorridos', label: 'kmsRecorridos' },
    { id: "23", content: 'Velocidad Media', label: 'velocidadMedia' },
    { id: "24", content: 'Velocidad maxima', label: 'velocidadMaxima' },
    { id: "25", content: 'Eventos Mobileye', label: 'eventosMobilEye' },
    { id: "26", content: 'Orden', label: 'orden' },
    { id: "27", content: 'Tiempo OT', label: 'diffTimeOt' },
    { id: "28", content: 'Temperatura', label: 'temperatura' },
]

export const fieldsDataPlaning = {
    NumPedido: "Nº Pedido",
    Cliente: "Cliente",
    Turno: "Turno",
    CodConductor: "Cod Conductor",
    Conductor: "Conductor",
    CabezaTractora: "Tractora",
    Semirremolque: "Remolque",
    HoraSalidaInstalacion: "Salida Instalacion",
    HoraLlegadaCliente: "Llegada Cliente",
    HoraSalidaCliente: "Salida Cliente",
    HoraLlegadaInstalacion: "Llegada Instalacion",
    CodCentro: "Cod Centro",
    OrgVentas: "Org Ventas",
    Canal: "Canal",
    Estado: "Estado",
}

export const ModalFieldsDataPlaning = {

    headers: {
        '1': { id: "1", content: 'Nº Pedido', label: 'NumPedido' },
        '2': { id: "2", content: 'Cliente', label: 'Cliente' },
        '3': { id: "3", content: 'Turno', label: 'Turno' },
        '4': { id: "4", content: 'Cod Conductor', label: 'CodConductor' },
        '5': { id: "5", content: 'Conductor', label: 'Conductor' },
        '6': { id: "6", content: 'Tractora', label: 'CabezaTractora' },
        '7': { id: "7", content: 'Remolque', label: 'Semirremolque' },
        '8': { id: "8", content: 'Salida Instalacion', label: 'HoraSalidaInstalacion' },
        '9': { id: "9", content: 'Llegada Cliente', label: 'HoraLlegadaCliente' },
        '10': { id: "10", content: 'Salida Cliente', label: 'HoraSalidaCliente' },
        '11': { id: "11", content: 'Llegada Instalacion', label: 'HoraLlegadaInstalacion' },
        '12': { id: "12", content: 'Cod Centro', label: 'CodCentro' },
        '13': { id: "13", content: 'Org Ventas', label: 'OrgVentas' },
        '14': { id: "14", content: 'Canal', label: 'Canal' },
        '15': { id: "15", content: 'Estado', label: 'Estado' }

    },
    columns: {
        'column-1': {
            id: 'column-1',
            title: 'Opciones',
            headersIds: []
        },
        'column-2': {
            id: 'column-2',
            title: 'Opciones seleccionadas',
            // headersIds: ["1","2","3","4","5","6","7","8","9","10","11","12","13","14","15","16","17","18","19","20","21","22","23","24"]
            headersIds: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15"]
        }
    },
    //facilitate reordering of the columns
    columnOrder: ['column-1', 'column-2'],
}

export const comboBoxDisa = {
    "Cabeza": "Cabeza",
    "Remolque": "Remolque",
    "Cliente": "Cliente",
    "Descripcion": "Descripción",
    "OrgVentas": "Org Ventas"
}

export const comboBoxPeru = {
    "Cabeza": "Tracto",
    "Remolque": "Tolva",
    "Cliente": "Destino",
    "Descripcion": "Orden Servicio",
    "OrgVentas": "Pto.Planificación"
}

export const comboBoxLogistics = {
    "Cabeza": "Cabeza",
    "Remolque": "Remolque",
    "Cliente": "Región",
    "Descripcion": "Descripción",
    "OrgVentas": "Org Ventas"
}

export default {
    dataTranslateDisa,
    dataTranslateSanfernando,
    listDataTranslateDisa,
    listDataTranslateSanfernando,
    SanfernandoStartHeaders,
    DisaStartHeaders,
    fieldsDataPlaning,
    ModalFieldsDataPlaning,
    DisaPlaningStartHeaders,
    comboBoxDisa,
    comboBoxPeru,
    listDataTranslateLogistics, dataTranslateLogistics,
    logisticsStartHeaders,
    comboBoxLogistics

};
