import React, { Component } from 'react'
import { Route, Switch } from "react-router-dom";
import Mapa from "./modules/map/Map";
import SignIn from "../src/modules/users/login/SignIn";
import NotFound from "./modules/global/notFound/NotFound";
import AppliedRoute from "../src/components/AppliedRoute";
import TemporalAccessList from './modules/users/temporalAccessFolder/TemporalAccessList';
import ControlTemporalAccessList from './modules/users/temporalAccessFolder/ControlTemporalAccessList';
import ExpireKeyErr from "./modules/global/ExpireKeyErr/ExpireKeyErr";
import CheckToken from '../src/modules/map/CheckToken';
import DrawerComponent from '../src/modules/global/dashboard/DrawerComponent';
import ControlTemporalAccessGroup from '../src/modules/users/temporalAccessFolder/ControlTemporalAccessGroup';
import CheckTokenFromYaLlego from '../src/modules/map/CheckTokenFromYaLlego';
import SondasBT from './modules/SondasBT/SondasBT';
import ControlSondasBT from './modules/SondasBT/ControlSondasBT';
import ExpireKeySondasBT from './modules/global/ExpireKeyErr/ExpireKeySondasBT';
import BuzonSugerencias from './modules/BuzonSugerencias/BuzonSugerencias';
import ControlBuzonSugerencias from './modules/BuzonSugerencias/ControlBuzonSugerencias';
import RecurringPois from './modules/RecurringPois/RecurringPois';
import ControlRecurringPois from './modules/RecurringPois/ControlRecurringPois';

export default class Routes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            companyName: '',
        }
    }

    getCompanyName = (companyName) => {
        this.setState({
            companyName: companyName.toLowerCase()
        })
    }
    render() {
        return (
            <Switch>
                <AppliedRoute path="/" exact component={SignIn} appProps={this.props.appProps} getCompanyName={this.getCompanyName} />
                <AppliedRoute path="/token/:token" exact component={SignIn} appProps={this.props.appProps} getCompanyName={this.getCompanyName} />
                <AppliedRoute path="/token/:token/:date/notification/" exact component={SignIn} appProps={this.props.appProps} getCompanyName={this.getCompanyName} />
                {/* <AppliedRoute path={"/menu/"+ this.state.companyName} companyName={this.state.companyName} exact component={Menu} appProps={this.props.appProps} /> */}
                <AppliedRoute path={"/map/:origin" + this.state.companyName} exact component={Mapa} appProps={this.props.appProps} />
                {/*<AppliedRoute path={"/taskOrderList/"+ this.state.companyName} companyName={this.state.companyName} exact component={taskOrderList} appProps={this.props.appProps} />
                <AppliedRoute path={"/Planning/"+ this.state.companyName} companyName={this.state.companyName} exact component={dataTablePlaning} appProps={this.props.appProps} /> */}
                <AppliedRoute path={"/accesotemporal/"} companyName={this.state.companyName} exact component={TemporalAccessList} appProps={this.props.appProps} />
                <AppliedRoute path={"/sondas-bt/"} companyName={this.state.companyName} exact component={SondasBT} appProps={this.props.appProps} />
                <AppliedRoute path={"/buzon-sugerencias/"} companyName={this.state.companyName} exact component={BuzonSugerencias} appProps={this.props.appProps} />
                <AppliedRoute path={"/gestion-grupos-acceso-temporal/"} companyName={this.state.companyName} exact component={ControlTemporalAccessGroup} appProps={this.props.appProps} />

                <AppliedRoute path={"/pois-recurrentes/"} companyName={this.state.companyName} exact component={RecurringPois} appProps={this.props.appProps} />

                <AppliedRoute path={"/pois-recurrentes/:locale/:key"} exact component={ControlRecurringPois} appProps={this.props.appProps} />
                <AppliedRoute path="/controlaccesotemporal/:locale/:key" exact component={ControlTemporalAccessList} appProps={this.props.appProps} />
                <AppliedRoute path="/sondas-bt/:locale/:key" exact component={ControlSondasBT} appProps={this.props.appProps} />
                <AppliedRoute path="/buzon-sugerencias/:locale/:key" exact component={ControlBuzonSugerencias} appProps={this.props.appProps} />
                <AppliedRoute path="/checktoken/:token" exact component={CheckToken} appProps={this.props.appProps} />
                <AppliedRoute path="/checktoken/:token/:email" exact component={CheckTokenFromYaLlego} appProps={this.props.appProps} />
                <AppliedRoute path="/ExpireKeyErr/" component={ExpireKeyErr} />
                <AppliedRoute path="/ExpireKeyErrSondasBT/" component={ExpireKeySondasBT} />
                <AppliedRoute path="/dashboard" component={DrawerComponent} companyName={this.state.companyName} coming={this.props.coming} appProps={this.props.appProps} />

                <Route component={NotFound} />
            </Switch>

        )
    }
}

// export default function Routes({ appProps }) {
//     console.log(appProps)
//     return (
//         <Switch>
//             <AppliedRoute path="/" exact component={SignIn} appProps={appProps} />
//             <AppliedRoute path="/menu" exact component={Menu} appProps={appProps} />
//             <AppliedRoute path="/map" exact component={Mapa} appProps={appProps} />
//             <AppliedRoute path="/taskOrderList" exact component={taskOrderList} appProps={appProps} />

//             <Route component={NotFound} />
//         </Switch>
//     );
// }