import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TextField,
  Select,
  MenuItem,
  makeStyles,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
} from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { withScriptjs, withGoogleMap, GoogleMap, Marker, Polygon } from "react-google-maps";
import config from "../../config";
import CloseIcon from '@material-ui/icons/Close';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import TableContainer from '@material-ui/core/TableContainer';
import QuantityField from './QuantityField';
import Autocomplete from '@material-ui/lab/Autocomplete';

const { MarkerClusterer } = require("react-google-maps/lib/components/addons/MarkerClusterer");


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  tableContainer: {

    boxShadow: theme.shadows[2],
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    height: "500px",
    borderRadius: theme.shape.borderRadius,

  },
  formContainer: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    height: "450px",
    boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.12)",
    borderRadius: 0,
  },
  stickyHeader: {
    position: "sticky",
    top: 0,
    backgroundColor: theme.palette.background.paper, // Fondo para que se vea bien al hacer scroll
    zIndex: 1, // Asegúrate de que esté por encima del contenido
  },
  closeIcon: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    cursor: 'pointer',
  },
  centeredCell: {
    textAlign: "center",

  },
  selectedRow: {
    backgroundColor: "rgba(200, 200, 200, 0.3)", // Fondo gris claro
    borderLeft: `4px solid red`, // Borde rojo en la izquierda
    paddingLeft: "10px"
  },
  mapContainer: {
    height: "500px",
    width: "100%",
    borderRadius: theme.shape.borderRadius,
    overflow: "hidden",
    boxShadow: theme.shadows[2],
  },
  formHeader: {
    backgroundColor: '#f4f8fa',
    padding: theme.spacing(1, 2),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
    width: '100%',
    position: 'relative',
    top: 0,
    left: 0,
    right: 0,
    boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
  },
  formFooter: {
    backgroundColor: '#f4f8fa',
    padding: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
    width: '100%',
    boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
  },
  formFields: {
    marginBottom: theme.spacing(2),
  },
  actionCell: {

    justifyContent: "flex-start",
    alignItems: "center",
  },
  actionButtonsContainer: {
    display: "flex",
    flexWrap: "wrap", // Esto solo se aplica a los botones
    gap: "10px",
  },
  button: {
    flex: "1 1", // Opcional: asegura que los botones ocupen espacio proporcional si necesario
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  muiTableCellRoot: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "250px",
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },

}));

const stopsData = [
  {
    id: 1,
    location: "Calle Triana, 12, Las Palmas de Gran Canaria",
    coordinates: { lat: 28.09975, lng: -15.41343 },
    stops: 5,
    vehicles: 1,
  },
  {
    id: 2,
    location: "Calle Mayor de Triana, 22, Las Palmas de Gran Canaria",
    coordinates: { lat: 28.10012, lng: -15.41358 },
    stops: 7,
    vehicles: 2,
  },
  {
    id: 3,
    location: "Plaza de Cairasco, Las Palmas de Gran Canaria",
    coordinates: { lat: 28.10085, lng: -15.41396 },
    stops: 4,
    vehicles: 1,
  },
  {
    id: 4,
    location: "Calle Mendizábal, 5, Las Palmas de Gran Canaria",
    coordinates: { lat: 28.10031, lng: -15.41450 },
    stops: 6,
    vehicles: 3,
  },
  {
    id: 5,
    location: "Calle Pérez Galdós, 15, Las Palmas de Gran Canaria",
    coordinates: { lat: 28.09998, lng: -15.41288 },
    stops: 3,
    vehicles: 1,
  },
  {
    id: 6,
    location: "Calle Domingo J. Navarro, 9, Las Palmas de Gran Canaria",
    coordinates: { lat: 28.09953, lng: -15.41250 },
    stops: 2,
    vehicles: 1,
  },
  {
    id: 7,
    location: "Calle Perdomo, 18, Las Palmas de Gran Canaria",
    coordinates: { lat: 28.10065, lng: -15.41276 },
    stops: 8,
    vehicles: 2,
  },
  {
    id: 8,
    location: "Plaza Hurtado de Mendoza, Las Palmas de Gran Canaria",
    coordinates: { lat: 28.10110, lng: -15.41316 },
    stops: 9,
    vehicles: 4,
  },
  {
    id: 9,
    location: "Calle Cano, 6, Las Palmas de Gran Canaria",
    coordinates: { lat: 28.09970, lng: -15.41300 },
    stops: 5,
    vehicles: 1,
  },
  {
    id: 10,
    location: "Calle Obispo Codina, 3, Las Palmas de Gran Canaria",
    coordinates: { lat: 28.10048, lng: -15.41400 },
    stops: 4,
    vehicles: 2,
  },
  // Nuevos registros cercanos pero dispersos
  {
    id: 11,
    location: "Calle Mesa y López, 50, Las Palmas de Gran Canaria",
    coordinates: { lat: 28.09910, lng: -15.41500 },
    stops: 6,
    vehicles: 2,
  },
  {
    id: 12,
    location: "Calle General Vives, 33, Las Palmas de Gran Canaria",
    coordinates: { lat: 28.10100, lng: -15.41450 },
    stops: 5,
    vehicles: 3,
  },
  {
    id: 13,
    location: "Avenida de la Constitución, 15, Las Palmas de Gran Canaria",
    coordinates: { lat: 28.10200, lng: -15.41370 },
    stops: 7,
    vehicles: 2,
  },
  {
    id: 14,
    location: "Calle León y Castillo, 52, Las Palmas de Gran Canaria",
    coordinates: { lat: 28.10010, lng: -15.41550 },
    stops: 3,
    vehicles: 1,
  },
  {
    id: 15,
    location: "Calle Juan de Quesada, 7, Las Palmas de Gran Canaria",
    coordinates: { lat: 28.10020, lng: -15.41520 },
    stops: 4,
    vehicles: 2,
  },
  {
    id: 16,
    location: "Avenida José Mesa y López, 75, Las Palmas de Gran Canaria",
    coordinates: { lat: 28.09960, lng: -15.41580 },
    stops: 5,
    vehicles: 3,
  },
  {
    id: 17,
    location: "Calle de Rafael Cabrera, 10, Las Palmas de Gran Canaria",
    coordinates: { lat: 28.10050, lng: -15.41610 },
    stops: 6,
    vehicles: 1,
  },
  {
    id: 18,
    location: "Calle Francisco Gourié, 3, Las Palmas de Gran Canaria",
    coordinates: { lat: 28.10120, lng: -15.41540 },
    stops: 4,
    vehicles: 2,
  },
  {
    id: 19,
    location: "Avenida de la Isleta, 24, Las Palmas de Gran Canaria",
    coordinates: { lat: 28.09880, lng: -15.41480 },
    stops: 8,
    vehicles: 4,
  },
  {
    id: 20,
    location: "Calle de la Cebolla, 9, Las Palmas de Gran Canaria",
    coordinates: { lat: 28.09900, lng: -15.41410 },
    stops: 5,
    vehicles: 3,
  },
];

const MapComponent = withScriptjs(
  withGoogleMap((props) => {
    const generatePolygonCoordinates = (center, radius) => {
      const coordinates = [];

      // Calculamos las coordenadas de las 4 esquinas del cuadrado
      const latOffset = radius / 111320;  // 1 grado de latitud ≈ 111.32 km
      const lngOffset = radius / (111320 * Math.cos(center.lat * (Math.PI / 180)));  // Ajuste por la curvatura de la Tierra

      coordinates.push({ lat: center.lat + latOffset, lng: center.lng - lngOffset }); // Esquina noreste
      coordinates.push({ lat: center.lat + latOffset, lng: center.lng + lngOffset }); // Esquina sureste
      coordinates.push({ lat: center.lat - latOffset, lng: center.lng + lngOffset }); // Esquina suroeste
      coordinates.push({ lat: center.lat - latOffset, lng: center.lng - lngOffset }); // Esquina noroeste

      return coordinates;
    };

    const handlePolygonDrag = (newCoords) => {
      if (props.onPolygonUpdate) {
        props.onPolygonUpdate(newCoords); // Llama a una función de callback para actualizar el estado
      }
    };

    return (
      <GoogleMap
        defaultZoom={17}
        defaultCenter={{ lat: 28.09975, lng: -15.41343 }}
        zoom={props.zoom}
        center={props.selectedStop ? props.selectedStop.coordinates : { lat: 28.09975, lng: -15.41343 }}
      >
        <MarkerClusterer>
          {props.stops.map((stop) => {
            if (props.isConvertingPoi && stop.id !== props.selectedStop.id) {
              return null;
            }
            return (
              <Marker
                key={stop.id}
                position={stop.coordinates}
                onClick={() => props.onMarkerClick(stop)}
              />
            );
          })}
        </MarkerClusterer>

        {props.selectedStop && props.showCircle && (
          <Polygon
            paths={generatePolygonCoordinates(props.selectedStop.coordinates, props.selectedStop.radius)}
            options={{
              fillColor: "#5dab22",
              fillOpacity: 0.45,
              strokeColor: "#5dab22",
              strokeOpacity: 1,
              strokeWeight: 3,
              draggable: true, // Hacer que el polígono sea arrastrable
              editable: true, // Permitir que el usuario edite las vértices
            }}
            onMouseUp={(event) => {
              if (event.overlay && event.overlay.getPath) {
                const updatedCoords = event.overlay.getPath().getArray().map(coord => ({
                  lat: coord.lat(),
                  lng: coord.lng(),
                }));
                handlePolygonDrag(updatedCoords); // Actualiza las coordenadas después de moverlas
              } else {
                console.error("event.overlay no es un polígono o no tiene getPath");
              }
            }}

          />
        )}
      </GoogleMap>
    );
  })
);

const RecurringPois = () => {
  const classes = useStyles();
  const [selectedStop, setSelectedStop] = useState(null);
  const [formVisible, setFormVisible] = useState(false);
  const [stops, setStops] = useState(stopsData);
  const [zoom, setZoom] = useState(17); // Estado para el zoom
  const [radius, setRadius] = useState(100); // Radio por defecto
  const [isConvertingPoi, setIsConvertingPoi] = useState(false);
  const [previousZoom, setPreviousZoom] = useState(17); // Estado para el zoom previo
  const [showCircle, setShowCircle] = useState(true); // Estado para controlar la visibilidad del círculo
  //const [quantity, setQuantity] = useState(1);
  const [openDialog, setOpenDialog] = useState(false);
  const [newType, setNewType] = useState('');
  const [types, setTypes] = useState(['Tipo1', 'Tipo2']); // Lista de tipos
  const [selectedCountry, setSelectedCountry] = useState(null);

  const rowRefs = useRef([]);
  const quantityRef = useRef(1);

  useEffect(() => {
    if (selectedStop) {
      // Verificamos si la fila seleccionada tiene referencia
      const selectedRowRef = rowRefs.current[selectedStop.id - 1]; // Asumimos que el ID es único y comienza en 1
      if (selectedRowRef) {
        selectedRowRef.scrollIntoView({
          behavior: "smooth", // Animación suave
          block: "center", // Asegura que la fila quede centrada
        });
      }
    }
  }, [selectedStop]);

  const handleConvert = (stop) => {
    setPreviousZoom(zoom); // Guardar el zoom previo
    setSelectedStop({ ...stop, radius }); // Añadir el radio al selectedStop
    setFormVisible(true);
    setZoom(18); // Establecer un zoom mayor cuando se selecciona una parada
    setIsConvertingPoi(true);
    setShowCircle(true); // Mostrar el círculo
  };

  const handleReject = (id) => {
    setStops((prev) => prev.filter((stop) => stop.id !== id));
  };

  const handleMarkerClick = (stop) => {
    setSelectedStop(stop);

  };

  const handleCloseForm = () => {
    setFormVisible(false);
    setIsConvertingPoi(false);
    setZoom(previousZoom); // Restaurar el zoom previo
    setShowCircle(false); // Mostrar el círculo
    setSelectedStop(null);
  };

  const handleRadiusChange = (event) => {
    const newRadius = event.target.value;
    setRadius(newRadius);
    // Actualizamos el valor del radio en selectedStop
    if (selectedStop) {
      setSelectedStop({
        ...selectedStop,
        radius: newRadius
      });
    }
  };

  const handlePolygonUpdate = (newCoords) => {
    if (newCoords && newCoords[0]) {
      setSelectedStop((prev) => ({
        ...prev,
        coordinates: newCoords[0],
      }));
    } else {
      console.error("No coordinates found or invalid polygon");
    }
  };

  const handleAddType = () => {
    if (newType.trim()) {
      setTypes((prevTypes) => [...prevTypes, newType.trim()]); // Agregar el nuevo tipo
      setNewType('');
      setOpenDialog(false); // Cerrar el diálogo
    }
  };

  /*const handleIncrement = () => {
    setQuantity(prevQuantity => prevQuantity + 1);
  };

  const handleDecrement = () => {
    if (quantity > 1) {
      setQuantity(prevQuantity => prevQuantity - 1);
    }
  };*/

  const handleQuantityChange = (newQuantity) => {
    quantityRef.current = newQuantity; // Actualiza el valor en el ref
    console.log('Nuevo valor de quantity:', quantityRef.current); // Puedes usar este valor donde lo necesites
  };

  return (
    <Grid container spacing={3} className={classes.root}>
      <Grid item xs={12} className={classes.header}>
        <Typography style={{ fontSize: '18px', fontWeight: 'bold' }} variant="body2">Paradas Recurrentes |</Typography>
        <Typography style={{ marginTop: '15px', fontSize: '16px', fontWeight: 'bold' }} variant="body2">
          Paradas recomendadas que deberías convertir en puntos de interés (POI)
        </Typography>
        <Typography style={{ fontStyle: 'italic', fontSize: '16px' }} variant="body2">
          Convertir las paradas recurrentes en POIs te ayuda a mejorar la gestión
          de las rutas, obtener información específica e identificar patrones
          recurrentes.
        </Typography>
        {/* <Typography style={{ marginTop: '15px', fontSize: '16px' }} variant="body2" >
          <span style={{ fontWeight: 'bold' }}>Semana 23 |</span> desde el lunes, 2 de junio hasta domingo, 8 de junio de
          2025
        </Typography> */}
      </Grid>
      <Grid item xs={12} md={3} >
        <TextField
          size="medium"
          variant="outlined"
          id="date"
          label="Fecha inicio"
          type="date"
          defaultValue="2017-05-24"
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
        />

      </Grid>
      <Grid item xs={12} md={3} >
        <TextField
          size="medium"
          variant="outlined"
          id="date"
          label="Fecha fin"
          type="date"

          defaultValue="2017-05-24"
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
        />

      </Grid>
      <Grid item xs={12} md={2} >
        <QuantityField onChange={handleQuantityChange} />
      </Grid>
      <Grid item xs={12} md={6}>
        {formVisible && (
          <div className={classes.formHeader}>
            <Typography variant="h6">Convertir parada recurrente en POI</Typography>
            <CloseIcon className={classes.closeIcon} onClick={handleCloseForm} />
          </div>
        )}
        <Paper className={formVisible ? classes.formContainer : classes.tableContainer}>
          {formVisible ? (
            <div>
              <Typography variant="body1">Localización</Typography>
              <Typography variant="body2">{selectedStop.location}</Typography>

              <TextField
                variant="outlined"
                label="Nombre del punto de interés"
                fullWidth
                style={{ marginBottom: 25, marginTop: 20 }}
              />
              <FormControl fullWidth variant="outlined" className={classes.formFields}>
                <InputLabel htmlFor="filled-age-native-simple">Tipo</InputLabel>
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                  {/* Select */}
                  <Select
                    fullWidth
                    label="Tipo"
                    variant="outlined"
                    margin="normal"
                    inputProps={{
                      name: 'Tipo',
                      id: 'filled-age-native-simple',
                    }}
                    className={classes.formFields}
                  >
                    <MenuItem value="">Selecciona un tipo</MenuItem>
                    <MenuItem value="Tipo1">Tipo 1</MenuItem>
                    <MenuItem value="Tipo2">Tipo 2</MenuItem>
                  </Select>

                  {/* Botón Añadir Tipo */}
                  <Button
                    variant="text"
                    color="primary"
                    onClick={() => setOpenDialog(true)}
                    style={{ whiteSpace: 'nowrap' }} // Evita que el texto del botón se desborde
                  >
                    [ Añadir tipo ]
                  </Button>
                </div>
              </FormControl>
              <FormControl variant="outlined" fullWidth className={classes.formFields}>
                <InputLabel htmlFor="Radio">Radio</InputLabel>
                <OutlinedInput
                  fullWidth
                  id="outlined-adornment-weight"
                  value={radius}
                  label="Radio"
                  onChange={handleRadiusChange}
                  endAdornment={<InputAdornment position="end">m</InputAdornment>}
                  inputProps={{
                    name: 'Radio',
                    id: 'Radio',
                  }}
                  labelWidth={0}
                />
                <FormHelperText>Área de acción del POI</FormHelperText>
              </FormControl>

              <FormControl variant="outlined" fullWidth className={classes.formFields}>
                <InputLabel className={classes.formControl} htmlFor="Radio">Radio</InputLabel>
                <OutlinedInput
                  fullWidth
                  id="outlined-adornment-weight"
                  value={radius}
                  label="Radio"
                  onChange={handleRadiusChange}
                  endAdornment={<InputAdornment position="end">min</InputAdornment>}
                  inputProps={{
                    name: 'min',
                    id: 'min',
                  }}
                  labelWidth={0}
                />
                <FormHelperText>Es el tiempo mínimo que el vehículo debe permanecer dentro del área del POI</FormHelperText>
              </FormControl>



            </div>
          ) : (
            <TableContainer style={{ maxHeight: '500px' }}>
              <Table stickyHeader style={{ borderCollapse: "collapse" }}>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Localización</TableCell>
                    <TableCell className={classes.centeredCell}>Paradas</TableCell>
                    <TableCell className={classes.centeredCell}>Vehículos</TableCell>
                    <TableCell>Acciones</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {stops.map((stop, index) => (
                    <TableRow
                      key={stop.id}
                      className={selectedStop?.id === stop.id ? classes.selectedRow : ""}
                      ref={(el) => rowRefs.current[index] = el}
                    >
                      <TableCell className={classes.MuiTableCellRoot}>{stop.location}</TableCell>
                      <TableCell className={classes.centeredCell}>{stop.stops}</TableCell>
                      <TableCell className={classes.centeredCell}>{stop.vehicles}</TableCell>
                      <TableCell className={classes.actionCell}>
                        <div className={classes.actionButtonsContainer}>
                          <Button size="small" onClick={() => handleConvert(stop)} variant="outlined">
                            Convertir
                          </Button>
                          <Button
                            size="small"
                            onClick={() => handleReject(stop.id)}
                            variant="outlined"
                            className={classes.button}
                          >
                            Rechazar
                          </Button>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>

            </TableContainer>

          )}
        </Paper>

        {formVisible && (
          <div className={classes.formFooter}>
            <Button variant="outlined" color="default" size="small">
              Guardar POI
            </Button>
          </div>
        )}
      </Grid>

      <Grid item xs={12} md={6}>
        <div className={classes.mapContainer}>
          <MapComponent
            stops={stops}
            selectedStop={selectedStop}
            zoom={zoom}
            isConvertingPoi={isConvertingPoi}
            onMarkerClick={handleMarkerClick}
            showCircle={showCircle}
            onPolygonUpdate={handlePolygonUpdate}
            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${config.KeyGoogleMaps}&v=3.exp&libraries=geometry,drawing,places`}
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `100%` }} />}
            mapElement={<div style={{ height: `100%` }} />}
          />
        </div>
      </Grid>
      {/* Dialog para añadir un tipo */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Añadir Tipo</DialogTitle>
        <DialogContent>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <TextField
              autoFocus
              margin="none"
              label="Nombre del tipo"
              variant="outlined"
              value={newType}
              onChange={(e) => setNewType(e.target.value)}
              style={{ width: 300, marginRight: '20px' }} // Espacio entre los elementos
            />
            <Autocomplete
              id="country-select-demo"
              style={{ width: 300 }}
              options={countries}
              value={selectedCountry}
              onChange={(event, newValue) => {
                setSelectedCountry(newValue);
              }}
              getOptionLabel={(option) => option.label}
              renderOption={(option) => (
                <React.Fragment>
                  <img
                    src={option.image}
                    alt="Tipo POI"
                    style={{ width: 20, height: 20, marginRight: 10 }}
                  />
                  {option.label}
                </React.Fragment>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Icono"
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: selectedCountry ? (
                      <InputAdornment position="start">
                        <img
                          src={selectedCountry.image}
                          alt="Icono seleccionado"
                          style={{ width: 20, height: 20 }}
                        />
                      </InputAdornment>
                    ) : null,
                  }}
                />
              )}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="secondary">
            Cancelar
          </Button>
          <Button onClick={handleAddType} color="primary">
            Añadir
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

// From https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js
const countries = [
  { code: 'POI1', label: 'Tipo POI 1', image: 'https://comerciales.4gflota.com/iconos_web/iconosTipoPOI/imagen13.png' },
  { code: 'POI2', label: 'Tipo POI 2', image: 'https://comerciales.4gflota.com/iconos_web/iconosTipoPOI/imagen14.png' },
  { code: 'POI3', label: 'Tipo POI 3', image: 'https://comerciales.4gflota.com/iconos_web/iconosTipoPOI/imagen15.png' },
];
// export default withTranslation()(TemporalAccessList);
export default withRouter(compose(
  withTranslation(),
  withStyles(useStyles, { withTheme: true }),


)(RecurringPois))


