import React, { Component } from 'react';
import config from '../../config';
import { withRouter } from 'react-router-dom';


class ControlRecurringPois extends Component {

    state = {
        accessToken: '',
        isLoading: true
    }

    componentDidMount() {
        // this.autoLogin();
        console.log("entra");
        this.getAccessControlList();
    }

    goToTemporalAccess = async (accessToken, locale) => {
        this.props.userHasAuthenticated(true);
        // console.log("entra");
        this.props.history.push({
            pathname: '/pois-recurrentes/',
            state: {
                userDataInfo: '',
                AccessToken: accessToken,
                urlApi: config.apiGateway.URL,
                locale: locale
            },
            accessToken: accessToken,
        });
    }

    async getAccessControlList() {
        let response = {};
        const settings = {
            method: 'GET',
        };
        let key = this.props.match.params.key.split("%");
        key = key[0] + "%25"+ key[1];
        
        // console.log(this.props.match.params.key);
        // IsActive(bool), Email, Observations
        let responseServices = await fetch(`${config.apiGateway.URL}AccessControl/login?` + key, settings);
        // let responseServices = await fetch(config.apiGateway.URL + "/AccessControl?IsActive=false&Email=''&Observations=", settings);

        if (responseServices.ok) {
            if (responseServices !== null || responseServices !== undefined) {
                try {
                    response =  await responseServices.text();
                    this.goToTemporalAccess(response, this.props.match.params.locale);
                    // this.goToMap(response.data);
                    // console.log(response);
                    // this.goToMap(response);
                } catch (error) {
                    response = null;
                }
            }
            // response = null;


        } else {
            // alert("HTTP-Error: " + responseServices.status);
            this.goToPageErr();
            
        }
    }

    goToPageErr = async () => {
        this.props.userHasAuthenticated(false);
        // console.log("entra");
        this.props.history.push({
            pathname: '/ExpireKeyErr/',
            state: {
                userDataInfo: '',
                locale: this.props.match.params.locale
            }
        });
    }

    render() {
        return (
            <div>
                {this.state.isLoading ?
                    <Loader/>
                    :
                    null
                }

            </div>
        )
    }
}

const Loader = () => (
    <div style={{margin: "auto"}}>
      <div className='pin bounce'></div>
      <div className='pulse'></div>
      <img className="bounceImg imgLogo" src="https://staticcontent.inelcan.com/img/4gflota_logo_new.png" alt="" />
    </div>
  );

export default withRouter(ControlRecurringPois);
