import React, { useState } from 'react';
import { TextField, InputAdornment, IconButton } from '@material-ui/core';
import { Add, Remove } from '@material-ui/icons';

const QuantityField = ({ onChange }) => {
  const [quantity, setQuantity] = useState(1);

  const handleIncrement = () => {
    const newQuantity = quantity + 1;
    setQuantity(newQuantity);
    if (onChange) onChange(newQuantity); // Notifica al padre
  };

  const handleDecrement = () => {
    if (quantity > 1) {
      const newQuantity = quantity - 1;
      setQuantity(newQuantity);
      if (onChange) onChange(newQuantity); // Notifica al padre
    }
  };

  return (
    <TextField
      label="Duración parada en minutos"
      value={quantity}
      onChange={(e) => {
        const newValue = parseInt(e.target.value, 10) || 1;
        setQuantity(newValue > 0 ? newValue : 1);
        if (onChange) onChange(newValue > 0 ? newValue : 1); // Notifica al padre
      }}
      type="number"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <IconButton onClick={handleDecrement}>
              <Remove />
            </IconButton>
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handleIncrement}>
              <Add />
            </IconButton>
          </InputAdornment>
        ),
      }}
      variant="outlined"
    />
  );
};

export default QuantityField;
